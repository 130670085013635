import React, { useEffect } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { setHeader, setInterceptors } from "./utils/interceptor";
import "./App.css";
import AppRoutes from "./routes";
import { Auth0Provider, AppState } from "@auth0/auth0-react";
import { PermissionContextProvider } from "./contexts/permissionContext";
import { BrowserRouter } from "react-router-dom";
import { MUI_X_PRO_LICENSE_KEY } from "./constants/commonConstants";
import ThemeContext from "./ui-library/theme/ThemeContext";

function App() {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    throw new Error("Missing Auth0 environment variables");
  }
  useEffect(() => {
    setHeader();
    setInterceptors();
    LicenseInfo.setLicenseKey(MUI_X_PRO_LICENSE_KEY);
  }, []);

  const onRedirectCallback = (appState?: AppState) => {
    window.history.replaceState(
      {},
      document.title,
      appState?.returnTo || window.location.pathname
    );
  };
  return (
    <BrowserRouter>
      <ThemeContext>
        <PermissionContextProvider>
          <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
              audience: audience,
              redirect_uri: `${window.location.origin}/callback`,
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            useRefreshTokensFallback
            cacheLocation="localstorage"
          >
            <div className="App">
              <AppRoutes />
            </div>
          </Auth0Provider>
        </PermissionContextProvider>
      </ThemeContext>
    </BrowserRouter>
  );
}

export default App;
