import { Box, Checkbox, Grid, Stack } from "@mui/material";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CampaignListTabs,
  breadCrumbDataCampaignsList,
} from "../../../constants/mailCentralConstant/MailCentralConstant";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { API_STATUS } from "../../../../../constants/commonConstants";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { isEmpty } from "lodash";
import { HorizontalTabs } from "@trica-equity/ui-library.tabs.horizontal-tabs";

const CampaignsList = () => {
  const navigate = useNavigate();
  const navigationFunction = () => navigate(-1);
  const navigateToCreate = () => navigate("/campaign/new-campaign");

  const [selectedTab, setSelectedTab] = useState(CampaignListTabs[0].value);

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const [selectedCheckBox, setSelectedCheckBox] = useState([]);
  const handleCheckbox = (id: string) => {};

  useEffect(() => {
    setFormattedData([
      {
        campaign_id: 698,
        past_campaigns: "asish",
        sent_scheduled: "19 nov",
        list_name: "asish list",
      },
      {
        campaign_id: 699,
        past_campaigns: "asish",
        sent_scheduled: "19 nov",
        list_name: "asish list",
      },
    ]);
  }, []);

  const column = [
    {
      field: "checkbox",
      headerName: "",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => {
        const [ch, setCh] = useState(false);
        return (
          <Checkbox
            checked={ch}
            onChange={(e) => {
              setCh(e.target.checked);
              handleCheckbox(params.row?.campaign_id);
              let arr: any = [...selectedCheckBox];
              if (
                selectedCheckBox.includes(params?.row?.campaign_id as never)
              ) {
                arr = arr.filter(function (ele: any) {
                  return ele != params?.row?.campaign_id;
                });
              } else {
                arr.push(params?.row?.campaign_id);
              }
              setSelectedCheckBox(arr);
            }}
          />
        );
      },
    },
    {
      field: "campaign_id",
      headerName: "Campaign Id",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "past_campaigns",
      headerName: "Past Campaigns",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "sent_scheduled",
      headerName: "Sent/Scheduled",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "list_name",
      headerName: "List Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 3,
      minWidth: 300,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Stack direction="row" gap={1}>
            <OutlinedButton
              onClick={() =>
                navigate(`/campaign/edit-campaign/${params?.row?.campaign_id}`)
              }
              customStyles={{
                color: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),
              }}
            >
              Edit
            </OutlinedButton>
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),
              }}
            >
              Clone
            </OutlinedButton>
            <OutlinedButton
              customStyles={{
                color: getColor("error", 500),
                borderColor: getColor("error", 500),
              }}
            >
              Delete
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbDataCampaignsList?.steps}
            pageHeader={{
              ...breadCrumbDataCampaignsList?.pageHeader,
              navigationFunction,
            }}
            stepKey={breadCrumbDataCampaignsList?.stepKey}
            breadcrumbSeparator={
              breadCrumbDataCampaignsList?.breadcrumbSeparator
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" gap={1} justifyContent={"end"}>
            {selectedCheckBox?.length ? (
              <ActionButton onClick={navigateToCreate}>
                Send Test Email
              </ActionButton>
            ) : null}
            <ActionButton onClick={navigateToCreate}>New Campaign</ActionButton>
          </Stack>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={CampaignListTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
      />

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <TeComplexTable
            rows={formattedData}
            columns={column}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
    </Box>
  );
};

export default CampaignsList;
