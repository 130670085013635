import axios from "axios";

const setInterceptors = () => {
  axios.interceptors.request.use(
    async (config) => {
      const accessToken = localStorage.getItem("token");
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.data) {
        try {
          const { status } = error.response;
          if (status === 401 || status === 490) {
            window.location.href = "/logout";
          }
          return Promise.reject(error?.response?.data);
        } catch (err) {
          console.error("setInterceptors -> response -> err", err);
        }
      }
      return Promise.reject(error);
    }
  );
};

const setHeader = () => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
};

export { setInterceptors, setHeader };
