export const breadCrumbDataCADocManagement = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Drawdown Payment Status",
    navigationButton: false,
  },
};

export const breadCrumbDataSchemeDoc = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Drawdown Payment Status",
    navigationButton: false,
  },
};
