export const RsvpTabs: any = [
  {
    name: "CURRENT EVENT",
    value: "current_event",
  },
  {
    name: "PAST EVENT",
    value: "past_event",
  },
];

export const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Listing Events",
    navigationButton: false,
  },
};
