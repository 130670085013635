"use client";

import { Box, Divider, Grid, Typography } from "@mui/material";
import { HorizontalTabs } from "@trica-equity/ui-library.tabs.horizontal-tabs";
import { useState } from "react";
import CreateSyndicate from "./CreateSyndicate";
import UpdateSyndicate from "./UpdateSyndicate";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";

const tabs: any = [
  {
    name: "Create Syndicate",
    value: "create_syndicate"
  },
  {
    name: "Update Syndicate",
    value: "update_syndicate"
  }
];

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Syndicate Management",
      titleDisplay: false
    }
  ],
  pageHeader: {
    header: "Create/Update Syndicate",
    navigationButton: false
  }
};

export default function CreateUpdateSyndicate() {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>
      <HorizontalTabs
        customTabsStyles={{ paddingTop: 4 }}
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      <br />
      {selectedTab === "create_syndicate" && <CreateSyndicate />}
      {selectedTab === "update_syndicate" && <UpdateSyndicate />}
    </Box>
  );
}

