import { makeStyles } from "@mui/styles";

export const sideMainLayout = {
  marginWhenSideBarOpen: "205px",
  marginWhenSideBarClose: "64px",
};

export default makeStyles({
  homeOnSideBarOpen: {
    marginLeft: sideMainLayout.marginWhenSideBarOpen,
    paddingBottom: "10px",
    transition: "margin 1s ease-in-out",
  },
  homeOnSideBarClose: {
    marginLeft: sideMainLayout.marginWhenSideBarClose,
    paddingBottom: "10px",
    transition: "margin 1s ease-in-out",
  },
  appBarHeight: { height: "54px" },
  mainBody: {
    padding: "20px 30px",
    minHeight: "84vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
});
