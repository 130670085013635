import { API_STATUS } from "../../../../constants/commonConstants";
import UserManagementManager from "../../managers/userManagementManager";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import EmailIcon from "@mui/icons-material/Email";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";

const columns = [
  {
    field: "name",
    headerName: "Name",
    headerAlign: "left",
    flex: 2,
    minWidth: 150,
  },
  {
    field: "email",
    headerName: "Email",
    headerAlign: "left",
    flex: 2,
    minWidth: 350,
  },
  {
    field: "date",
    headerName: "Applied On",
    align: "center",
    flex: 2,
    minWidth: 150,
  },

  {
    field: "action",
    headerName: "Action",
    align: "center",
    flex: 6,
    minWidth: 340,

    renderCell: (params: any) => {
      const actionData = params.row.action;
      return (
        <IconButton
          sx={{
            color: getColor("action_button", 200),
          }}
          onClick={() => {
            window.open(
              `mailto:${params.row?.email}?subject=SendMail&body=Description`
            );
          }}
        >
          <Tooltip title="Mail">
            <EmailIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      );
    },
  },
];

export default function ProfilePendingInvestor() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [value, setValue] = useState("");

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);

      let data = await userManagementManager.getNonSebiInvestors(value, page);

      console.log(data);
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, [page]);

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Profile Pending Investor",
      navigationButton: false,
    },
  };

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Name/Email"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>

      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <TeComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
