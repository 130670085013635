import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../loader/loading";
import { useAuth0 } from "@auth0/auth0-react";

const Logout = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      await logout({
        logoutParams: {
          returnTo: `${window.location.origin}`,
        },
      });
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      localStorage.clear();
      navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return <Loading />;
};

export default Logout;
