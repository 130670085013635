import React, { FC, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import ScheduleModal from "./ScheduleModal";

const Confirm = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Box p={2}>
      <ScheduleModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container>
        <Grid item xs={8}>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>From Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>From Name</Typography>
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Subject</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>Subject</Typography>
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>List</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>List</Typography>
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Recepients</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>0</Typography>
            </Grid>
          </Stack>

          <br />
          <Stack direction="row" gap={3} justifyContent="end">
            <ActionButton>Save</ActionButton>
            <ActionButton onClick={() => setOpenModal(true)}>
              Schedule
            </ActionButton>
            <ActionButton>Send Test Email</ActionButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Confirm;
