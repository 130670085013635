import { Autocomplete, Box, TextField } from "@mui/material";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useStyles } from "./styles";
import useDebounce from "../../utils/useDebounce";

type OptionObjType = { label: string; value: string };
type OptionArrayType = OptionObjType[];

type LvAutoCompleteType = {
  label: string;
  options: OptionArrayType;
  setOptions: (val: any) => void;
  selectedObject: OptionObjType;
  setSelectedObject: (val: any) => void;
  onChange?: (val: any) => Promise<void>;
  onSelect?: (val: any) => void;
  helperText?: string;
};

const LvAutoComplete = React.forwardRef<
  HTMLBaseElement,
  PropsWithChildren<LvAutoCompleteType>
>((props, ref) => {
  const classes = useStyles();
  const {
    label,
    options,
    setOptions,
    selectedObject,
    setSelectedObject,
    onChange,
    onSelect,
    helperText = "Please enter 1 or more character",
  } = props;
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const textDeb = useDebounce(text, 500);

  const onChangeOnTextChange = async () => {
    if (textDeb) {
      onChange && (await onChange(textDeb));
      setLoading(false);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    onChangeOnTextChange();
  }, [textDeb]);

  return (
    <Autocomplete
      ref={ref}
      loading={loading}
      fullWidth
      className={classes.root}
      options={options}
      value={options.find(
        (option: any) => option["value"] === selectedObject?.value
      )}
      popupIcon={
        <img
          src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
          alt="arrow-down"
        />
      }
      onChange={(event: any, value: any) => {
        setSelectedObject(value);
        onSelect && onSelect(value);
      }}
      getOptionLabel={(option: any) => `${option.label}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          className={classes.textField}
          onChange={(e) => {
            setLoading(!!e.target.value);
            setText(e.target.value);
          }}
          helperText={helperText}
        />
      )}
      PopperComponent={(props) => {
        return props?.open ? (
          <Box className={`${classes.optionsBox}`}>
            <div
              className={classes.menuBox}
              style={{
                maxHeight: "200px",
              }}
            >
              <>{props.children}</>
            </div>
            <div onMouseDown={(event) => event.preventDefault()}></div>
          </Box>
        ) : (
          <></>
        );
      }}
      PaperComponent={(props) => {
        return (
          <Box
            sx={{
              "& .MuiAutocomplete-listbox": {
                maxHeight: "none !important",
              },
            }}
            {...props}
          />
        );
      }}
    />
  );
});

export default LvAutoComplete;
