import { Box, Grid, Stack } from "@mui/material";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import React, { useContext, useState } from "react";
import {
  BreadCrumbDataInvestorDocument,
  DocumentCategoryOptions,
} from "../../constants/documentConstants";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { API_STATUS } from "../../../../constants/commonConstants";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import { isEmpty } from "lodash";
import DocumentManagement from "../../managers/documentManager";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

const InvestorDocuments = () => {
  const DocumentCategoryOptionsExtended = [
    { label: "All", value: "all" },
    ...DocumentCategoryOptions,
  ];

  const [selectedUser, setSelectedUser] = useState<any>({});
  const [userOptions, setUserOptions] = useState<any>([]);

  const [documentCategory, setDocumentCategory] = useState<any>({});

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);

  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    // handle api call
    // getDealDocuments(page + 1);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 100,
    },
    {
      field: "investor_name",
      headerName: "Investor Name",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "formated_document_category",
      headerName: "Document Category",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "formated_document_name",
      headerName: "Document Name",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "last_updated_date",
      headerName: "Uploaded Date",
      sortable: false,
      flex: 2,
      minWidth: 180,
    },
    {
      field: "last_updated_by",
      headerName: "Last Uploaded By",
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 2,
      minWidth: 300,

      renderCell: (params: any) => {
        return (
          <Stack direction="row" gap={3}>
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),
              }}
              onClick={() => {
                viewFile(params.row?.document_url);
              }}
            >
              View
            </OutlinedButton>

            <OutlinedButton
              customStyles={{
                color: getColor("error", 500),
              }}
            >
              Reject
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataInvestorDocument?.steps}
            pageHeader={BreadCrumbDataInvestorDocument.pageHeader}
            stepKey={BreadCrumbDataInvestorDocument.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <LvAutoComplete
              label="Enter Deal Name"
              options={userOptions}
              setOptions={setUserOptions}
              selectedObject={selectedUser}
              setSelectedObject={setSelectedUser}
              onSelect={() => setUserOptions([])}
            />
          </Box>
          <Box width={"190px"}>
            <CoreDropdown
              label="Document Category"
              options={DocumentCategoryOptionsExtended}
              value={documentCategory}
              onClickMenuItem={(value: any) => setDocumentCategory(value)}
            />
          </Box>
          <ActionButton>Bulk Upload Document</ActionButton>
          <ActionButton>Upload Document</ActionButton>
        </Grid>
      </Grid>

      {apiStatus === API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <TeComplexTable
          rows={formattedData}
          columns={columns}
          rowHeight={40}
          headerHeight={50}
          minWidth={750}
          filterMode="server"
          leftPinnedColumns={["name"]}
          enableAdvancedFiltering
          isUnFilteredDataPresent={!isEmpty(formattedData)}
          enableAutoHeight={true}
          pageSize={20}
          page={page}
          enablePagination
          totalRowCount={count}
          handlePageChange={handlePageChange}
          paginationMode="server"
        />
      )}
    </Box>
  );
};

export default InvestorDocuments;
