import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosRequestHeaders,
} from "axios";
// import { removeUserSession } from "../common/utils/common";
import { httpMethods } from "../constants/commonConstants";
import callApi from "./callApi";

interface RequestInterceptor {
  callback: (config: AxiosRequestConfig) => AxiosRequestConfig;
  errorHandler: (error: AxiosError) => Promise<AxiosError>;
}

interface ResponseInterceptor {
  callback: (response: AxiosResponse) => Promise<AxiosResponse>;
  errorHandler: (error: AxiosError) => Promise<AxiosError | AxiosResponse>;
}

export class HttpClient {
  private instance: AxiosInstance;
  private defaultRequestInterceptor: RequestInterceptor;
  private defaultResponseInterceptor: ResponseInterceptor;
  private tokenGenerator?: () => Promise<string>;
  private requestInterceptor?: number;
  private responseInterceptor?: number;

  constructor(baseURL?: string) {
    this.instance = axios.create({
      baseURL: baseURL ?? process.env.API_BASE_URL,
    });
    this.defaultRequestInterceptor = this.getDefaultRequestInterceptor();
    this.defaultResponseInterceptor = this.getDefaultResponseInterceptor();
    this.setRequestInterceptor(this.defaultRequestInterceptor);
    this.setResponseInterceptor(this.defaultResponseInterceptor);
  }

  setTokenGenerator(tokenGenerator: () => Promise<string>): this {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  async getToken(): Promise<string> {
    if (!this.tokenGenerator) throw new Error("Token generator not set!");
    return this.tokenGenerator();
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }

  setRequestInterceptor(requestInterceptor: any): void {
    this.requestInterceptor = this.instance.interceptors.request.use(
      requestInterceptor.callback,
      requestInterceptor.errorHandler
    );
  }

  setResponseInterceptor(responseInterceptor: any): void {
    this.responseInterceptor = this.instance.interceptors.response.use(
      responseInterceptor.callback,
      responseInterceptor.errorHandler
    );
  }

  enableDefaultRequestInterceptor(): void {
    this.setRequestInterceptor(this.defaultRequestInterceptor);
  }

  enableDefaultResponseInterceptor(): void {
    this.setResponseInterceptor(this.defaultResponseInterceptor);
  }

  getDefaultRequestInterceptor() {
    return {
      callback: (config: any) => {
        const access_token = localStorage.getItem("token");
        if (!access_token) {
          //   removeUserSession();
          window.location.href = "/";
        }
        if (access_token) {
          config.headers = config.headers ?? ({} as AxiosRequestHeaders); // Ensure headers are defined
          config.headers["Authorization"] = `Bearer ${access_token}`;
        }
        config.headers = config.headers ?? ({} as AxiosRequestHeaders); // Ensure headers are defined
        config.headers["Content-Type"] = "application/json";
        return config;
      },
      errorHandler: (error: AxiosError): Promise<AxiosError> => {
        console.log("Error in request:", error);
        return Promise.reject(error);
      },
    };
  }

  getDefaultResponseInterceptor(): ResponseInterceptor {
    return {
      callback: (response: any) => Promise.resolve(response),
      errorHandler: async (error: any) => {
        console.log("Error in response:", error);

        if (error.response?.status === 490 && !error?.config?._retry) {
          error.config._retry = true;
          try {
            const token = await this.getToken();
            localStorage.setItem("token", token);
            this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
            this.instance.defaults.headers["Content-Type"] = "application/json";
            error.config.headers["Authorization"] = `Bearer ${token}`;
            const deviceUrl = `/v1/users/create_device_data/`;
            await callApi(deviceUrl, httpMethods.HTTP_POST, "", {}, true, true);
            return this.instance(error?.config);
          } catch (err) {
            console.log("Error from response error handler:", err);
            localStorage.removeItem("token");
            window.location.reload();
          }
        }

        if (error.response?.status === 401 || error.response?.status === 403) {
          //   removeUserSession();
          window.location.href = "/";
        }

        return Promise.reject(error);
      },
    };
  }
}
