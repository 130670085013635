import { Box, Grid } from "@mui/material";
import { CommonModal } from "@trica-equity/ui-library.modals.common-modal";
import React, { FC } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
};

const ScheduleModal: FC<NoteModalType> = (props) => {
  const { isOpen, handleClose } = props;
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={() => {}}
      title="Schedule Campaign"
      submitText="&nbsp;&nbsp;Schedule&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Cancel&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      <Box width="30vw">
        <Grid container gap={2}>
          <Box width="100%">
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Schedule At" />
              </LocalizationProvider>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default ScheduleModal;
