import { Divider, Grid } from "@mui/material";
import { Formik } from "formik";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { Box, TextField, Autocomplete, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import useDebounce from "../../../../utils/useDebounce";
import { useStyles } from "./styles";
import { useContext } from "react";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../contexts/authContext";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Startup's Panel",
    navigationButton: false,
  },
};

export default function StartupPanel() {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const [selectedVal1, setSelectedVal1] = useState<any>({});
  const [option1, setOption1] = useState<any>([]);
  const [text1, setText1] = useState("");
  const [loading1, setLoading1] = useState(false);

  const [selectedVal2, setSelectedVal2] = useState<any>({});
  const [option2, setOption2] = useState<any>([]);
  const [text2, setText2] = useState("");
  const [loading2, setLoading2] = useState(false);

  const [selectedVal3, setSelectedVal3] = useState<any>({});
  const [option3, setOption3] = useState<any>([]);
  const [text3, setText3] = useState("");
  const [loading3, setLoading3] = useState(false);

  const handleTextChange1 = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "admin_search_startups"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption1(result);
      setLoading1(false);
    } catch (err) {
      setLoading1(false);
      console.log(err);
    }
  };

  const handleTextChange2 = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "admin_search_startup_scheme"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption2(result);
      setLoading2(false);
    } catch (err) {
      setLoading2(false);
      console.log(err);
    }
  };

  const handleTextChange3 = async () => {
    try {
      let result = await userManagementManager.getLookupData(
        "",
        "select_user_hashtags"
      );

      result = result?.private_hashtags.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));

      setOption3(result);
      setLoading3(false);
    } catch (err) {
      setLoading3(false);
      console.log(err);
    }
  };
  const textDeb1 = useDebounce(text1, 2000);
  const textDeb2 = useDebounce(text2, 2000);
  const textDeb3 = useDebounce(text3, 2000);

  useEffect(() => {
    if (textDeb1) {
      handleTextChange1(textDeb1);
    } else {
      setOption1([]);
    }
  }, [textDeb1]);

  useEffect(() => {
    if (textDeb2) {
      handleTextChange2(textDeb2);
    } else {
      setOption2([]);
    }
  }, [textDeb2]);

  useEffect(() => {
    handleTextChange3();
  }, []);

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Typography variant="h5" textAlign="left">
            Select Startup to View and take action
          </Typography>
          <Divider />
          <div style={{ marginTop: "30px" }}>
            <>
              <Autocomplete
                loading={loading1}
                fullWidth
                className={classes.root}
                options={option1}
                value={option1.find(
                  (option: any) => option["value"] === selectedVal1?.value
                )}
                popupIcon={
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down"
                  />
                }
                onChange={(event: any, value: any) => {
                  setSelectedVal1(value);
                  setOption1([]);
                }}
                getOptionLabel={(option: any) => `${option.label}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Select Startup"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    onChange={(e) => {
                      setLoading1(!!e.target.value);

                      setText1(e.target.value);
                    }}
                    helperText={"Please enter 1 or more character"}
                  />
                )}
                PopperComponent={(props) => {
                  return props?.open ? (
                    <Box className={`${classes.optionsBox}`}>
                      <div
                        className={classes.menuBox}
                        style={{
                          maxHeight: "200px",
                        }}
                      >
                        <>{props.children}</>
                      </div>
                      <div
                        onMouseDown={(event) => event.preventDefault()}
                      ></div>
                    </Box>
                  ) : (
                    <></>
                  );
                }}
                PaperComponent={(props) => {
                  return (
                    <Box
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "none !important",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
              />
              <br />
              <Autocomplete
                loading={loading2}
                fullWidth
                className={classes.root}
                options={option2}
                value={option2.find(
                  (option: any) => option["value"] === selectedVal2?.value
                )}
                popupIcon={
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down"
                  />
                }
                onChange={(event: any, value: any) => {
                  setSelectedVal2(value);
                  setOption2([]);
                }}
                getOptionLabel={(option: any) => `${option.label}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Enter Startup Scheme Name"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    onChange={(e) => {
                      setLoading2(!!e.target.value);
                      setText2(e.target.value);
                    }}
                    helperText={"Please enter 1 or more character"}
                  />
                )}
                PopperComponent={(props) => {
                  return props?.open ? (
                    <Box className={`${classes.optionsBox}`}>
                      <div
                        className={classes.menuBox}
                        style={{
                          maxHeight: "200px",
                        }}
                      >
                        <>{props.children}</>
                      </div>
                      <div
                        onMouseDown={(event) => event.preventDefault()}
                      ></div>
                    </Box>
                  ) : (
                    <></>
                  );
                }}
                PaperComponent={(props) => {
                  return (
                    <Box
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "none !important",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
              />
              <br />

              <Link
                aria-disabled={!selectedVal1?.value && !selectedVal2?.value}
                style={{
                  marginLeft: "auto",
                  display: "block",
                  width: "fit-content",
                  pointerEvents:
                    !selectedVal1?.value && !selectedVal2?.value
                      ? "none"
                      : "auto",
                }}
                to={`/startup/show-startup/${
                  selectedVal1?.value
                    ? selectedVal1?.value
                    : selectedVal2?.value
                    ? selectedVal2.value
                    : ""
                }/`}
              >
                <ActionButton
                  disabled={!selectedVal1?.value && !selectedVal2?.value}
                >
                  Go to Startup&apos;s Panel
                </ActionButton>
              </Link>
            </>
          </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Typography variant="h5" textAlign="left">
            Search Startups with Admin Hashtags
          </Typography>
          <Divider />
          <div style={{ marginTop: "30px" }}>
            <Formik initialValues={{ hashtag: "" }} onSubmit={() => {}}>
              <>
                <Autocomplete
                  loading={loading3}
                  fullWidth
                  className={classes.root}
                  options={option3}
                  value={option3.find(
                    (option: any) => option["id"] === selectedVal3?.id
                  )}
                  popupIcon={
                    <img
                      src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                      alt="arrow-down"
                    />
                  }
                  onChange={(event: any, value: any) => {
                    setSelectedVal3(value);
                  }}
                  getOptionLabel={(option: any) => `${option.label}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Choose Hashtag"}
                      variant="outlined"
                      size="small"
                      className={classes.textField}
                      helperText={"Please enter 1 or more character"}
                    />
                  )}
                  PopperComponent={(props) => {
                    return props?.open ? (
                      <Box className={`${classes.optionsBox}`}>
                        <div
                          className={classes.menuBox}
                          style={{
                            maxHeight: "200px",
                          }}
                        >
                          <>{props.children}</>
                        </div>
                        <div
                          onMouseDown={(event) => event.preventDefault()}
                        ></div>
                      </Box>
                    ) : (
                      <></>
                    );
                  }}
                  PaperComponent={(props) => {
                    return (
                      <Box
                        sx={{
                          "& .MuiAutocomplete-listbox": {
                            maxHeight: "none !important",
                          },
                        }}
                        {...props}
                      />
                    );
                  }}
                />

                <br />
                <ActionButton
                  badgeStyles={{
                    marginLeft: "auto",
                    display: "block",
                    width: "fit-content",
                  }}
                >
                  Search
                </ActionButton>
              </>
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
