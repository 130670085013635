export enum StartupAdminActionType {
  PUBLISH = "publish",
  UNPUBLISH = "unpublish",
  DISABLE_DEAL_DASHBOARD_ACCESS = "disable_deal_dashboard_access",
  ENABLE_DEAL_DASHBOARD_ACCESS = "enable_deal_dashboard_access",
  SET_INVESTMENT_TYPE_AIF = "set_investment_type_aif",
  SET_INVESTMENT_TYPE_DIRECT = "set_investment_type_direct",
  ADD_MOU = "add_mou",
  REMOVE_MOU = "remove_mou",
  DISABLE_SUPER_STEALTH = "disable_super_stealth",
  ENABLE_SUPER_STEALTH = "enable_super_stealth",
  ENABLE_PRIVATE_SYNDICATE_STARTUP = "enable_private_syndicate_startup",
  DISABLE_PRIVATE_SYNDICATE_STARTUP = "disable_private_syndicate_startup",
}
