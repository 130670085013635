import { setInterceptors, setHeader } from "../utils/interceptor";
import { createContext, useEffect, useState } from "react";

export type UserAuthInfo = {
  token: string;
  user?: any;
};

export type AuthContextProps = {
  authState: UserAuthInfo;
  isUserAuthenticated: Function;
  isAuthLoading: boolean;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [authState, setAuthState] = useState<UserAuthInfo>({} as UserAuthInfo);

  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthState({ token });
      setHeader();
      setInterceptors();
    }
    setIsAuthLoading(false);
  }, []);

  const isUserAuthenticated = () => {
    const token = localStorage.getItem("token");
    return !!token;
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        isUserAuthenticated,
        isAuthLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
