import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { FileUpload } from "@trica-equity/ui-library.form-elements.file-upload";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

const CAApprovalProcess = () => {
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);

  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption(result);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ marginTop: "30px" }}>
      {" "}
      <Formik
        initialValues={{
          name: "",
          email: "",
          demo: true,
          role: "",
          fileList: [],
        }}
        onSubmit={() => {}}
      >
        {({ values }: any) => {
          return (
            <Grid container>
              <Grid item xs={8}>
                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Select Investor</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <LvAutoComplete
                      label="Select Investor User"
                      options={option}
                      setOptions={setOption}
                      selectedObject={selectedVal}
                      setSelectedObject={setSelectedVal}
                      onChange={handleTextChange}
                    />
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Upload Signed CA Doc</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FileUpload
                      customStyles={{ maxWidth: 490 }}
                      placeholder="select a file"
                      helperText="upload your file here"
                      // selectedFile={(file:any) => setFileList(file)}
                      multiple
                      value={values?.fileList ?? []}
                      accept={{
                        "image/svg": [".png", ".svg"],
                        "text/pdf": [".pdf"],
                      }}
                      title="File Upload Dialog Box"
                      hideDocumentVault
                    />
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Mark as Paid</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="(Note: Mark as checked only if the funds are received.)"
                    />
                  </Grid>
                </Stack>

                <Box marginLeft="auto" width="fit-content">
                  <ActionButton>Approve</ActionButton>
                </Box>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </div>
  );
};

export default CAApprovalProcess;
