export type InvestorObjType = { title: string; value: string };

export const investorType: InvestorObjType[] = [
  {
    title: "Business Owner",
    value: "business_owner",
  },
  {
    title: "Professional",
    value: "professional",
  },
  {
    title: "VC & PE Professional",
    value: "vc_pe_professional",
  },
  {
    title: "VC & PE Fund",
    value: "vc_pe_fund",
  },
  {
    title: "Angel Network",
    value: "angel_network",
  },
  {
    title: "Family Office",
    value: "family_office",
  },
  {
    title: "Startup Founder",
    value: "startup_founder",
  },
  {
    title: "Accelerator/Incubator",
    value: "accelerator_incubator",
  },
  {
    title: "Student",
    value: "student",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const NEW_VERIFY_INVESTOR_TAB: any = [
  {
    name: "AWAITING",
    value: "awaiting",
  },
  {
    name: "VERIFIED",
    value: "verified",
  },
  {
    name: "REJECTED",
    value: "rejected",
  },
];

export const VERIFY_PHONE_TAB: any = [
  {
    name: "AWAITING",
    value: "awaiting",
  },
  {
    name: "VERIFIED",
    value: "verified",
  },
  
];
