import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { Formik } from "formik";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { useState } from "react";
import UserManagementManager from "../../managers/userManagementManager";
import { toast } from "react-toastify";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";

export default function AddUser() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [addPromotion, setAddPromotion] = useState(false);
  const [onboardingFees, setOnboardingFees] = useState(25000);
  const [upfrontDrawdown, setUpfrontDrawdown] = useState(25000);
  const [placementFees, setPlacementFees] = useState(0);

  const handleAddUser = async () => {
    try {
      const result = await userManagementManager.addUser(
        name,
        email,
        addPromotion,
        onboardingFees,
        upfrontDrawdown,
        placementFees
      );
      if (result?.data) toast.success("User added successfully");
      setName("");
      setEmail("");
      setAddPromotion(false);
      setOnboardingFees(25000);
      setUpfrontDrawdown(25000);
      setPlacementFees(0);
    } catch (error: any) {
      toast.error(error?.detail);
    }
  };
  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Add User",
      navigationButton: false,
    },
  };
  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <div style={{ marginTop: "30px", textAlign: "left" }}>
            <Formik
              initialValues={{ name: "", email: "", demo: true, role: "" }}
              onSubmit={() => {}}
            >
              <>
                <CustomTextField
                  label={"Full Name"}
                  InputLabelProps={{}}
                  customStyles={{}}
                  error={false}
                  id="name"
                  name="name"
                  value={name}
                  onChange={(val: any) => setName(val)}
                  required
                />
                <br />
                <CustomTextField
                  label={"Email"}
                  InputLabelProps={{}}
                  customStyles={{}}
                  error={false}
                  id="email"
                  name="email"
                  value={email}
                  onChange={(val: any) => setEmail(val)}
                  required
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addPromotion}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAddPromotion(e.target.checked)
                      }
                    />
                  }
                  label="Add Promotion"
                />
                <br />
                <br />
                {addPromotion ? (
                  <>
                    {" "}
                    <CustomTextField
                      label={"Onboarding Fee"}
                      InputLabelProps={{}}
                      customStyles={{}}
                      error={false}
                      id="onboarding_fee"
                      name="onboarding_fee"
                      value={onboardingFees}
                      onChange={(val: any) => setOnboardingFees(val)}
                      type="number"
                    />
                    <br />
                    <CustomTextField
                      label={"Upfront Drawdown"}
                      InputLabelProps={{}}
                      customStyles={{}}
                      error={false}
                      id="upfront_drawdown"
                      name="upfront_drawdown"
                      value={upfrontDrawdown}
                      onChange={(val: any) => setUpfrontDrawdown(val)}
                      type="number"
                    />
                    <br />
                    <CustomTextField
                      label={"Placement Fee"}
                      InputLabelProps={{}}
                      customStyles={{}}
                      error={false}
                      id="placement_fee"
                      name="placement_fee"
                      value={placementFees}
                      onChange={(val: any) => setPlacementFees(val)}
                      type="number"
                    />{" "}
                  </>
                ) : null}
                <br />
                <Box sx={{ textAlign: "right" }}>
                  <ActionButton
                    onClick={handleAddUser}
                    disabled={!name?.trim() || !email?.trim()}
                  >
                    Create User
                  </ActionButton>
                </Box>
              </>
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
