import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import React, { useEffect, useState } from "react";
import {
  AND_OR,
  GROUP_TYPES_INVESTOR,
  GROUP_TYPES_STARTUP,
  USERTYPES,
  breadCrumbDataCreateList,
  breadCrumbDataEditList,
} from "../../../constants/mailCentralConstant/MailCentralConstant";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { RadioButton } from "@trica-equity/ui-library.form-elements.radio-button-select";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { Download } from "@mui/icons-material";
import AddUserModal from "./AddUserModal";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";

type QuizParams = {
  list_id: string;
};

const CreateMailerList = () => {
  // list id for edit list
  const { list_id = "" } = useParams<QuizParams>();
  const breadcrumbDataOnCondition = list_id
    ? breadCrumbDataEditList
    : breadCrumbDataCreateList;

  const navigate = useNavigate();
  const navigationFunction = () => navigate(-1);

  const [openModal, setOpenModal] = useState(false);

  const [listName, setListName] = useState("");
  const [userType, setUserType] = useState<any>(USERTYPES[0]);
  const [group, setGroup] = useState<any>(GROUP_TYPES_INVESTOR[0]?.value);
  const [andOr, setAndOr] = useState<any>(AND_OR[0]?.value);

  const [selectedVertical1, setSelectedVertical1] = useState<any>({});
  const [verticalOption1, setVerticalOption1] = useState<any>([]);
  const [selectedHorizontal1, setSelectedHorizontal1] = useState<any>({});
  const [horizontalOption1, setHorizontalOption1] = useState<any>([]);
  const [selectedVertical2, setSelectedVertical2] = useState<any>({});
  const [verticalOption2, setVerticalOption2] = useState<any>([]);
  const [selectedHorizontal2, setSelectedHorizontal2] = useState<any>({});
  const [horizontalOption2, setHorizontalOption2] = useState<any>([]);

  const [selectedStartupV, setSelectedStartupV] = useState<any>({});
  const [startupVOptions, setStartupVOptions] = useState<any>([]);
  const [selectedStartupH, setSelectedStartupH] = useState<any>({});
  const [startupHOptions, setStartupHOptions] = useState<any>([]);

  const [selectedLocation, setSelectedLocation] = useState<any>({});
  const [locationOptions, setLocationOptions] = useState<any>([]);

  const [selectedSyndicate, setSelectedSyndicate] = useState<any>({});
  const [syndicateOption, setSyndicateOption] = useState<any>([]);

  // set values for edit list
  useEffect(() => {
    if (list_id) {
      console.log(list_id);
      alert("edit for" + list_id);
    }
  }, [list_id]);

  return (
    <Box p={2}>
      <AddUserModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadcrumbDataOnCondition?.steps}
            pageHeader={{
              ...breadcrumbDataOnCondition?.pageHeader,
              navigationFunction,
            }}
            stepKey={breadcrumbDataOnCondition?.stepKey}
            breadcrumbSeparator={breadcrumbDataOnCondition?.breadcrumbSeparator}
          />
        </Grid>
        <Grid item xs={6}>
          <ActionButton
            badgeStyles={{
              display: "block",
              width: "fit-content",
              marginLeft: "auto",
            }}
          >
            <Download /> Download Sample CSV
          </ActionButton>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item xs={8}>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>List Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                name="list_name"
                required
                label="List Name"
                value={listName}
                onChange={(val: string) => setListName(val)}
              />
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Type Of User</Typography>
            </Grid>
            <Grid item xs={8}>
              <CoreDropdown
                options={USERTYPES}
                label="Type of User"
                value={userType}
                onClickMenuItem={(option: any) => {
                  setUserType(option);
                }}
              />
            </Grid>
          </Stack>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Select Group</Typography>
            </Grid>
            <Grid item xs={8}>
              <RadioButton
                options={
                  userType?.value === "investor"
                    ? GROUP_TYPES_INVESTOR
                    : GROUP_TYPES_STARTUP
                }
                name="group_type"
                value={group}
                onChange={(val: string) => {
                  setGroup(val);
                }}
              />
            </Grid>
          </Stack>

          {userType?.value === "investor" ? (
            <>
              {" "}
              <Stack direction="row" gap={3} mb={3}>
                <Grid item xs={4}>
                  <Typography>Sector of Expertise</Typography>
                </Grid>
                <Grid item xs={8}>
                  <LvAutoComplete
                    label={"Vertical Sectors"}
                    options={verticalOption1}
                    setOptions={setVerticalOption1}
                    selectedObject={selectedVertical1}
                    setSelectedObject={setSelectedVertical1}
                  />

                  <LvAutoComplete
                    label={"Horizontal Sectors"}
                    options={horizontalOption1}
                    setOptions={setHorizontalOption1}
                    selectedObject={selectedHorizontal1}
                    setSelectedObject={setSelectedHorizontal1}
                  />
                </Grid>
              </Stack>
              <Stack direction="row" mb={3} justifyContent="end" mr="15%">
                <RadioButton
                  options={AND_OR}
                  name="type"
                  value={andOr}
                  onChange={(val: string) => {
                    setAndOr(val);
                  }}
                />
              </Stack>
              <Stack direction="row" gap={3} mb={3}>
                <Grid item xs={4}>
                  <Typography>Sector of Investment</Typography>
                </Grid>
                <Grid item xs={8}>
                  <LvAutoComplete
                    label={"Vertical Sectors"}
                    options={verticalOption2}
                    setOptions={setVerticalOption2}
                    selectedObject={selectedVertical2}
                    setSelectedObject={setSelectedVertical2}
                  />
                  <LvAutoComplete
                    label={"Horizontal Sectors"}
                    options={horizontalOption2}
                    setOptions={setHorizontalOption2}
                    selectedObject={selectedHorizontal2}
                    setSelectedObject={setSelectedHorizontal2}
                  />
                </Grid>
              </Stack>
            </>
          ) : (
            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Startup Sectors</Typography>
              </Grid>
              <Grid item xs={8}>
                <LvAutoComplete
                  label={"Vertical Startups"}
                  options={startupVOptions}
                  setOptions={setStartupVOptions}
                  selectedObject={selectedStartupV}
                  setSelectedObject={setSelectedStartupV}
                />
                <LvAutoComplete
                  label={"Horizontal Startups"}
                  options={startupHOptions}
                  setOptions={setStartupHOptions}
                  selectedObject={selectedStartupH}
                  setSelectedObject={setSelectedStartupH}
                />
              </Grid>
            </Stack>
          )}

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Locations</Typography>
            </Grid>
            <Grid item xs={8}>
              <LvAutoComplete
                label={"Choose a City"}
                options={locationOptions}
                setOptions={setLocationOptions}
                selectedObject={selectedLocation}
                setSelectedObject={setSelectedLocation}
              />
            </Grid>
          </Stack>

          {userType?.value === "investor" ? (
            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Syndicate</Typography>
              </Grid>
              <Grid item xs={8}>
                <LvAutoComplete
                  label={"Choose a Syndicate"}
                  options={syndicateOption}
                  setOptions={setSyndicateOption}
                  selectedObject={selectedSyndicate}
                  setSelectedObject={setSelectedSyndicate}
                />
              </Grid>
            </Stack>
          ) : null}
          <br />
          <Stack direction="row" gap={2} justifyContent="end">
            <ActionButton>Search Witch Filter</ActionButton>
            <ActionButton>Reset Filters</ActionButton>
            <ActionButton>Import CSV</ActionButton>
            <ActionButton onClick={() => setOpenModal(true)}>
              Add User
            </ActionButton>
          </Stack>

          <br />
          <br />
          <Divider />
          <Typography variant="h6" textAlign="left">
            Final List
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateMailerList;
