export const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Institution's Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Institution Panel",
    navigationButton: false,
  },
};
