export const startupStageOptions = [
    {
      label: "Ideation",
      value: "ideation"
    },
    {
      label: "Proof Of Concept",
      value: "proof_of_concept"
    },
    {
      label: "Beta Launched",
      value: "beta_launched"
    },
    {
      label: "Early Revenues",
      value: "early_revenues"
    },
    {
      label: "Steady Revenues",
      value: "steady_revenues"
    }
  ];
  
  export const STARTUP_STAGES: any = {
    ideation: 1,
    proof_of_concept: 2,
    beta_launched: 3,
    early_revenues: 4,
    steady_revenues: 5
  };
  export const bussinessTypeOptions = [
    {
      label: "B2B",
      value: "B2B"
    },
    {
      label: "B2C",
      value: "B2C"
    }
  ];
  
  