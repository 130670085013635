export const KycStatusType = {
  PENDING: "lv_review_pending",
  IN_PROGRESS: "lv_review_in_progress",
  VERIFIED: "lv_verified"
};

export const KycStatusTabs: any = [
  {
    name: "PENDING",
    value: KycStatusType.PENDING
  },
  {
    name: "IN PROGRESS",
    value: KycStatusType.IN_PROGRESS
  },
  {
    name: "VERIFIED",
    value: KycStatusType.VERIFIED
  }
];

export const ReviewStatusOptions: any = [
  {
    label: "Pending",
    value: KycStatusType.PENDING
  },
  {
    label: "In Progress",
    value: KycStatusType.IN_PROGRESS
  },
  {
    label: "Verified",
    value: KycStatusType.VERIFIED
  }
];

export const KycStageType = {
  KYC_NOT_STARTED: "kyc_not_started",
  KYC_VALID_PAN: "pan_valid",
  KYC_CKYC_AVAILABLE: "ckyc_available",
  KYC_CKYC_CONFIRMED: "ckyc_confirmed",
  KYC_CKYC_INVALID_INFO: "ckyc_invalid_info",
  KYC_NO_PAN_CARD: "no_pan_card",
  KYC_CKYC_NOT_FOUND: "ckyc_not_found",
  KYC_MANUAL_PAN_INFO: "kyc_manual_pan_info"
};

export const KycStageOptions = [
  {
    label: "KYC Not Started",
    value: KycStageType.KYC_NOT_STARTED
  },
  {
    label: "PAN Valid",
    value: KycStageType.KYC_VALID_PAN
  },
  {
    label: "CKYC Available",
    value: KycStageType.KYC_CKYC_AVAILABLE
  },
  {
    label: "CKYC Confirmed",
    value: KycStageType.KYC_CKYC_CONFIRMED
  },
  {
    label: "CKYC Invalid Info",
    value: KycStageType.KYC_CKYC_INVALID_INFO
  },
  {
    label: "No Pan Card",
    value: KycStageType.KYC_NO_PAN_CARD
  },
  {
    label: "CKYC Not Found",
    value: KycStageType.KYC_CKYC_NOT_FOUND
  },
  {
    label: "KYC Manual Pan Info",
    value: KycStageType.KYC_MANUAL_PAN_INFO
  }
];

