import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { Box, FormLabel, Grid } from "@mui/material";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { useState } from "react";
import { useStyles } from "../../../userManagement/components/usersPanel/styles";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { OrderTypeOptions } from "../../constants/CreatePaymentConstants";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import DocumentManagement from "../../../documentManagement/managers/documentManager";
import { toast } from "react-toastify";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Create Payment Link",
    navigationButton: false,
  },
};

export default function CreatePaymentLink() {
  const classesAc = useStyles();
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);
  const documentManagementManager = new DocumentManagement(userData);
  const commitmentManagement = new CommitmentManagementManager();

  const [selectedNameEmail, setSelectedNameEmail] = useState<any>({});
  const [nameEmailOptions, setNameEmailOptions] = useState<any>([]);
  const handleInvestorSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setNameEmailOptions(result);
    } catch (err) {
      console.log(err);
    }
  };

  const [selectedInvestmentProfile, setSelectedInvestmentProfile] =
    useState<any>({});
  const [investmentProfileOptions, setInvestmentProfileOptions] = useState<any>(
    []
  );
  const handleProfileSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getInvestmentProfile(
        value,
        selectedNameEmail?.value
      );
      result = result?.data.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setInvestmentProfileOptions(result);
    } catch (err) {
      console.log(err);
    }
  };

  const [selectedStartup, setSelectedStartup] = useState<any>({});
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const handleSearchStartup = async (value: string) => {
    try {
      let result = await documentManagementManager.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setStartupOptions(result);
    } catch (error) {
      console.log(error);
    }
  };

  const [orderType, setOrderType] = useState(OrderTypeOptions[1]);
  const [amount, setAmount] = useState<number>();
  const [generating, setGenerating] = useState(false);
  const [showLink, setShowLink] = useState("");

  const handlePaymentLink = async () => {
    try {
      setGenerating(true);
      !selectedNameEmail?.value ||
        !selectedInvestmentProfile?.value ||
        !selectedStartup?.value ||
        !orderType?.value ||
        !amount;
      let result = await commitmentManagement.createPaymentLink(
        selectedInvestmentProfile?.value,
        orderType?.value,
        amount,
        selectedStartup?.value
      );
      toast.success("Payment link generated successfully");
      if (result) setShowLink(result?.payment_link);
      setGenerating(false);
    } catch (e: any) {
      toast.error(e);
      setGenerating(false);
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <br />
      <Grid container rowGap={3}>
        <Grid item xs={3}>
          <FormLabel>Investor Name or Email</FormLabel>
        </Grid>
        <Grid item xs={6}>
          <LvAutoComplete
            label="Investor Name or Email"
            options={nameEmailOptions}
            setOptions={setNameEmailOptions}
            selectedObject={selectedNameEmail}
            setSelectedObject={setSelectedNameEmail}
            onChange={handleInvestorSearch}
            onSelect={() => setNameEmailOptions([])}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          <FormLabel>Investment Profile</FormLabel>
        </Grid>
        <Grid item xs={6}>
          <LvAutoComplete
            label="Investment Profile"
            options={investmentProfileOptions}
            setOptions={setInvestmentProfileOptions}
            selectedObject={selectedInvestmentProfile}
            setSelectedObject={setSelectedInvestmentProfile}
            onChange={handleProfileSearch}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          <FormLabel>Order Type</FormLabel>
        </Grid>

        <Grid item xs={6}>
          <CoreDropdown
            label="Order Type"
            options={OrderTypeOptions}
            value={orderType}
            onClickMenuItem={(val: any) => setOrderType(val)}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          <FormLabel>Select Startup</FormLabel>
        </Grid>

        <Grid item xs={6}>
          <LvAutoComplete
            label="Select Startup"
            options={startupOptions}
            setOptions={setStartupOptions}
            selectedObject={selectedStartup}
            setSelectedObject={setSelectedStartup}
            onSelect={() => setStartupOptions([])}
            onChange={handleSearchStartup}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          <FormLabel>Amount</FormLabel>
        </Grid>

        <Grid item xs={6}>
          <CustomTextField
            type="number"
            value={amount}
            onChange={(val: any) => setAmount(val)}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={9}>
          <Box width="fit-content" ml="auto">
            <ActionButton
              disabled={
                !selectedNameEmail?.value ||
                !selectedInvestmentProfile?.value ||
                !selectedStartup?.value ||
                !orderType?.value ||
                !amount
              }
              onClick={handlePaymentLink}
            >
              {generating ? "Generating Payment Link" : "Create Payment Link"}
            </ActionButton>
          </Box>
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <br />
      {showLink && (
        <Grid container>
          <Grid item xs={3}>
            <FormLabel>Generated Payment Link</FormLabel>
          </Grid>

          <Grid item xs={9}>
            <a href={showLink} target="_blank">
              {showLink}
            </a>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
