import { DocumentTypes } from "../constants/documentConstants";
import {
  getDealDocuments,
  getInvestorDocuments,
  handleDocStatus,
  uploadBulkDocument,
  uploadDocument,
  searchDealName,
  uploadDealDocument,
  publishUnpublish,
  downloadDealDocument,
} from "../services/documentServices";
import { getFormattedDate } from "../../../utils/dateUtils";
import { toast } from "react-toastify";

type investorDocuments = {
  id: number;
  investor_name: string;
  document_type: string;
  document_name: string;
  notes: string;
  uploaded_date: string;
  uploaded_by: string;
  action: any;
};

type dealDocuments = {
  id: number;
  startup_name: string;
  document_category: string;
  document_name: string;
  notes: string;
  last_updated_by: string;
  last_updated_date: string;
  action: any;
};

export type actionButtonActions = {
  id: number;
  file: string;
  approve?: boolean;
  reject?: boolean;
  publish?: boolean;
  unpublish?: boolean;
  approveText?: string;
  rejectText?: string;
  viewText?: string;
  isOnboarding?: boolean;
};

type DealDocUploadType = {
  startup_id: string;
  document_category: string;
  document_name: string;
  document_url: any;
  document_notes: string;
};
export default class DocumentManagement {
  private userData: any;

  constructor(AuthContext: any) {
    this.userData = AuthContext.authState;
  }

  private getFormattedDealDocs = (data: any[]) => {
    let result: dealDocuments[] = [];
    data.forEach((item: any) => {
      result.push({
        id: item?.id ?? 0,
        startup_name: item?.startup_name ?? 0,
        document_category: item?.document_category ?? "",
        document_name: item?.formated_document_name ?? "",
        notes: item?.document_notes ?? "",
        last_updated_by: item?.last_updated_user ?? "",
        last_updated_date: getFormattedDate(item?.last_updated_date) ?? "",
        action: "",
        ...item,
      });
    });
    return result;
  };

  private getFormattedInvestorDocs = (data: any[]) => {
    let result: investorDocuments[] = [];
    data.forEach((item: any) => {
      result.push({
        id: item?.id ?? 0,
        investor_name: item?.investor_name ?? 0,
        document_type: item?.document_title ?? "",
        document_name: item?.document_type ?? "",
        notes: item?.document_notes ?? "",
        uploaded_by: item?.last_updated_by ?? "",
        uploaded_date: getFormattedDate(item?.created_date) ?? "",
        action: this.getActionsButtonData(item),
      });
    });
    return result;
  };

  private getActionsButtonData = (data: any) => {
    const {
      document_title: type = null,
      document_url = null,
      status = 0,
      publish = false,
      id = null,
    } = data;

    const actions: actionButtonActions = {
      id: id,
    } as actionButtonActions;

    if (type.toLowerCase() === "onboarding") {
      if (parseInt(status) === 0) {
        actions.approve = true;
        actions.reject = true;
      } else if (parseInt(status) === 1) {
        actions.reject = true;
      } else {
        actions.approve = true;
      }
      actions.approveText = "Approve";
      actions.rejectText = "Reject";
      actions.isOnboarding = true;
    } else {
      if (publish) actions.unpublish = true;
      else actions.publish = true;

      actions.approveText = "Publish";
      actions.rejectText = "Unpublish";
      actions.isOnboarding = false;
    }

    if (document_url) {
      actions.viewText = "View";
      actions.file = document_url;
    }

    return actions;
  };

  async getDealDocuments(
    page: number,
    deal_id?: string,
    document_category?: string,
    document_name?: string
  ) {
    try {
      let result = await getDealDocuments(
        page,
        deal_id,
        document_category,
        document_name
      );
      let formattedData = this.getFormattedDealDocs(result?.data?.results);
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getInvestorDocuments(
    page: number,
    investor_id?: string,
    investor_information_id?: string,
    document_title?: string
  ) {
    try {
      let result = await getInvestorDocuments(
        page,
        investor_id,
        investor_information_id,
        document_title
      );
      let formattedData = this.getFormattedInvestorDocs(result?.data?.results);
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async approveOnboardingDoc(id: number) {
    try {
      const body = {
        status: "1",
        last_updated_by_user_id: `${this.userData.user.id}`,
        last_updated_by: this.userData.user.name,
      };

      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }
  async rejectOnboardingDoc(id: number, rejectText: string) {
    try {
      const body = {
        status: "2",
        document_note: rejectText.trim(),
        last_updated_by_user_id: `${this.userData.user.id}`,
        last_updated_by: this.userData.user.name,
      };
      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }
  async publishInvestorDoc(id: number) {
    try {
      const body = {
        publish: true,
        last_updated_by_user_id: `${this.userData.user.id}`,
        last_updated_by: this.userData.user.name,
      };
      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }
  async unPublishInvestorDoc(id: number) {
    try {
      const body = {
        publish: false,
        last_updated_by_user_id: `${this.userData.user.id}`,
        last_updated_by: this.userData.user.name,
      };
      const res = await handleDocStatus(id, body);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async uploadBulkDocument(
    typeOfFile: string,
    year: string,
    quarter: string,
    file: any
  ) {
    try {
      let formData = new FormData();
      formData.append("document_type", typeOfFile);
      formData.append("bulk_document_file", file);
      formData.append("year", year);
      formData.append("quarter", quarter);
      const res = await uploadBulkDocument(formData);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async uploadDocument(
    user_id: string,
    investor_information_id: string,
    document_title: string,
    document_type: string,
    notes: string,
    year: string,
    quarter: string,
    document_url: any
  ) {
    try {
      let formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("investor_information_id", investor_information_id);
      formData.append("document_title", document_title);
      if (document_title === DocumentTypes.onboarding) {
        formData.append("document_type", document_type);
      }
      if (
        document_title === DocumentTypes.navdocument ||
        document_title === DocumentTypes.tax
      ) {
        formData.append("year", year);
      }
      if (document_title === DocumentTypes.unitstatement) {
        formData.append("year", year);
        formData.append("quarter", quarter);
      }
      formData.append("notes", notes);

      formData.append("document_url", document_url);

      const res = await uploadDocument(formData);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async searchDealName(search: string) {
    try {
      const res = await searchDealName(search);
      return { data: res.data.results };
    } catch (error) {
      throw error;
    }
  }

  async uploadDealDocument(data: DealDocUploadType) {
    try {
      let formData = new FormData();
      formData.append("startup_id", data.startup_id);
      formData.append("document_category", data.document_category);
      formData.append("document_name", data.document_name);

      formData.append("notes", data.document_notes);

      formData.append("document_url", data.document_url);

      const res = await uploadDealDocument(formData);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async downloadDealDocument(
    page: number,
    startup_id: string,
    document_category: string,
    document_name: string
  ) {
    try {
      const res = await downloadDealDocument(
        page,
        startup_id,
        document_category,
        document_name
      );
      return res;
    } catch (error: any) {
      toast.error(error);
      throw error;
    }
  }

  async publishUnpublish(id: string, publish: boolean) {
    try {
      const data = {
        last_updated_user_id: this.userData.user.id,
        publish,
      };
      const res = await publishUnpublish(id, data);

      return res;
    } catch (error) {
      throw error;
    }
  }
}
