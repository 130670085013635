import { makeStyles } from "@mui/styles";
import { getColor } from "@trica-equity/ui-library.styles.colors";

export const useStyles = makeStyles({
  root: {
    "& .MuiChip-filled": {
      marginRight: "0.5rem",
    },
  },
  optionsBox: {
    width: "inherit !important",
    background: getColor("shades", 0),
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    zIndex: "100",
    marginTop: "40px",
  },
  optionsBoxPadding: {
    padding: "20px 0px",
  },
  menuBox: {
    padding: "0px 20px",
    overflow: "auto",
  },
  highlight: {
    backgroundColor: getColor("background", 1000),
  },

  marginYHeader: {
    margin: "0.5rem 0 0 0!important",
  },
  link: {
    color: getColor("accent", 0),
    cursor: "pointer",
    display: "flex",
    fontWeight: 500,
    textDecorationLine: "underline",
    margin: 0,
  },
  marginRight: {
    marginRight: "0.5rem",
  },
  chip: {
    fontSize: "0.875rem !important",
    color: `${getColor("extra", 1100)} !important`,
    backgroundColor: `${getColor("extra", 1200)} !important`,
    borderRadius: "0.25rem !important",
  },
  closeIcon: {
    color: `${getColor("extra", 1100)} !important`,
  },
  textField: {
    "& .MuiInputLabel-outlined": {
      color: getColor("extra", 1900),
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: getColor("primary", 700),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: getColor("primary", 700),
    },
    "& .MuiFormControl-root": {
      border: `1px solid ${getColor("extra", 2000)}`,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${getColor("extra", 2000)}`,
      },
      "&:hover fieldset": {
        borderColor: getColor("primary", 700),
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${getColor("primary", 700)}`,
      },
    },
  },
  fieldItem: { padding: "0px 0px 20px 0px" },
});
