import { API_STATUS } from "../../../../constants/commonConstants";
import DashboardManager from "../../../dashboard/managers/dashboardManager";
import { Box, Grid } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { Link } from "react-router-dom";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import {
  BreadCrumbDataNomineeInformation,
  caDocStatus,
  faasNonFaasTypes,
} from "../../constants/dashboardConstants";

const columns = [
  {
    field: "id",
    headerName: "S.No",
    align: "center",
    flex: 2,
    minWidth: 120,
  },
  {
    field: "name",
    headerName: "Investor Profile Name",
    headerAlign: "left",
    flex: 2,
    minWidth: 200,
  },
  {
    field: "nominee_name",
    headerName: "Nominee Name",
    headerAlign: "left",
    flex: 2,
    minWidth: 150,
  },

  {
    field: "date",
    headerName: "Nominee DOB",
    align: "center",
    flex: 2,
    minWidth: 150,
  },
  {
    field: "nominee_relation_Type",
    headerName: "Nominee Relation Type",
    headerAlign: "left",
    flex: 2,
    minWidth: 200,
  },

  {
    field: "faas_non_faas",
    headerName: "Nominee Address",
    headerAlign: "left",
    flex: 2,
    minWidth: 170,
  },
  {
    field: "kyc_kra_check",
    headerName: "Is Nominee Minor",
    headerAlign: "left",
    flex: 2,
    minWidth: 170,
  },
  {
    field: "view_profile",
    headerName: "View Profile",
    headerAlign: "left",
    minWidth: 180,
    renderCell: (params: any) => {
      const actionData = params.row.action;
      return (
        <Link
          style={{ textDecoration: "none" }}
          to={`/user_management/show_user/${params.row?.user_id}/`}
        >
          {params?.row?.name}{" "}
        </Link>
      );
    },
  },
];

export default function NomineeInformation() {
  const authContext = useContext(AuthContext);
  const dashboardManager = new DashboardManager(authContext);
  const [value, setValue] = useState("");

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);

      let data = await dashboardManager.getNomineeInformation(value, page);

      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataNomineeInformation?.steps}
            pageHeader={BreadCrumbDataNomineeInformation.pageHeader}
            stepKey={BreadCrumbDataNomineeInformation.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Nominee Email"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton
          // onClick={handleSearchClick}
          >
            Search
          </ActionButton>
        </Grid>
      </Grid>
      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="50px"
            columnHeaderHeight="40px"
          />
        ) : (
          <TeComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={50}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
