import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonModal } from "@trica-equity/ui-library.modals.common-modal";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { FileUpload } from "@trica-equity/ui-library.form-elements.file-upload";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { AuthContext } from "../../../../../contexts/authContext";
import Loader from "../../../../loader/loading";
import { useStyles } from "../styles";
import StartupManagement from "../../../managers/startupManager";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";

type StartupUpdateModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  handleOpenRejectWithoutMail: () => void;
  handleOpenRejectWithMail: () => void;
};

const StartupUpdateModal: FC<StartupUpdateModalType> = (props) => {
  const { isOpen, handleClose } = props;
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [editorState, setEditorState] = useState("");
  const [file, setFile] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dealName, setDealName] = useState("");
  const [emailSubject, setEmailSubject] = useState("");

  const [sendEmailToInvestor, setSendEmailToInvestor] = useState(false);

  const [selectedDeal, setSelectedDeal] = useState<any>({});
  const [dealOptions, setDealOptions] = useState<any>([]);
  const handleSearchDealName = async (value: string) => {
    try {
      let result = await startupManagement.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setDealOptions(result);
    } catch (error) {
      console.log(error);
    }
  };

  const [updateOptions, setUpdateOptions] = useState<any[]>([]);
  const [selectedUpdate, setSelectedUpdate] = useState<any>(null);
  const fetchOptions = async () => {
    try {
      const result = [
        { label: "Others", value: "others" },
        { label: "EGM/AGM/SCN", value: "egm_agm_scn" },
        {
          label: "Future fundraise/preemptive/right issue",
          value: "future_fundraise_preemprive_right_issue",
        },
        { label: "Distress", value: "distress" },
        { label: "Exit", value: "exit" },
        { label: "Conversion", value: "conversion" },
      ];
      setUpdateOptions(result);
      setSelectedUpdate(result[0] || {});
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  useEffect(() => {
    fetchOptions();
  }, []);

  const handleEditorChange = (content: string) => {
    setEditorState(content);
  };

  const handleSubmit = () => {
    const fileNames = file.map((f) => f.name).join(", ");
    const sendEmail = sendEmailToInvestor ? "Yes" : "No";
    const alertMessage = `
      Deal Name: ${dealName}
      Update Type: ${selectedUpdate.label}
      Email Subject: ${emailSubject}
      Email Content: ${editorState}
      File(s): ${fileNames}
      Send Email to Investors: ${sendEmail}
    `;

    alert(alertMessage);
    handleClose();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="Send Investor Communication"
      submitText="&nbsp;&nbsp;Submit&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Close&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      <ToastContainer />
      <Box width="50vw">
        {isLoading && <Loader />}

        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Deal Name
            </Grid>
            <Grid item xs={8}>
              <div className={classes.fieldItem} style={{ marginTop: "5px" }}>
                <LvAutoComplete
                  label="Enter Deal Name"
                  options={dealOptions}
                  setOptions={setDealOptions}
                  selectedObject={selectedDeal}
                  setSelectedObject={setSelectedDeal}
                  onSelect={() => setDealOptions([])}
                  onChange={handleSearchDealName}
                  helperText={
                    "Note: result in order Startup Name -> Date of funding -> Scheme Name -> Startup ID -> Primary Startup ID"
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Update Type
            </Grid>
            <Grid item xs={8}>
              <LvAutoComplete
                label={"Choose Update Type"}
                options={updateOptions}
                setOptions={setUpdateOptions}
                selectedObject={selectedUpdate}
                setSelectedObject={setSelectedUpdate}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Email Subject
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                type="emailsubject"
                value={emailSubject}
                onChange={(val: any) => setEmailSubject(val)}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Email Content
            </Grid>
            <Grid item xs={8}>
              <ReactQuill
                theme="snow"
                value={editorState}
                onChange={handleEditorChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Select File
            </Grid>
            <Grid item xs={8}>
              <FileUpload
                customStyles={{ maxWidth: 550 }}
                placeholder="select a file"
                helperText="upload your file here"
                selectedFile={(file: any) => setFile(file)}
                multiple
                value={file}
                accept={{ "image/svg": [".png", ".svg"], "text/pdf": [".pdf"] }}
                title="File Upload Dialog Box"
                hideDocumentVault
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Publish Date
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Publish Date" />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Send Email to Investors
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmailToInvestor}
                    onChange={(e) => setSendEmailToInvestor(e.target.checked)}
                  />
                }
                label=""
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default StartupUpdateModal;
