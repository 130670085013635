import React, { useState, useEffect, useContext, useCallback } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import AifManagementManager from "../../managers/aifManagementManagers";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { IconConstant } from "../../../../constants/IconConstants";
import { NoDataAvailable } from "@trica-equity/ui-library.error.no-data-available";

const CaDocStatusTable = (props: { status: string }) => {
  let { status } = props;
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const cadocManager = new AifManagementManager();
  const [page, setPage] = useState(1);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      sortable: false,
      flex: 2,
    },
    {
      field: "user_id",
      headerName: "User Id",
      sortable: false,
      flex: 2,
    },
    {
      field: "investor_name",
      headerName: "Investor Name",
      sortable: false,
      flex: 2,
    },
    {
      field: "investor_email",
      headerName: "Investor Email",
      sortable: false,
      flex: 2,
    },
    {
      field: "document_id",
      headerName: "Document Id",
      sortable: false,
      flex: 2,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      sortable: false,
      flex: 2,
    },
    {
      field: "last_updated_date",
      headerName: "Last Updated Date",
      sortable: false,
      flex: 2,
    },
  ];

  const getCaDocStatus = async (currentPage = 1) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } = await cadocManager.getCaDocsByStatus(
        status,
        currentPage
      );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getCaDocStatus(page + 1);
  };

  useEffect(() => {
    setPage(1);
    getCaDocStatus();
  }, [status]);

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  return apiStatus == API_STATUS.LOADING ? (
    <TablePlaceholder
      rows={6}
      columns={6}
      rowHeight="40px"
      columnHeaderHeight="40px"
    />
  ) : (
    <div>
      <TeComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
    </div>
  );
};

export default CaDocStatusTable;
