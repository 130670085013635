import ReactLoading from "react-loading";

function Loading() {
  return (
    <div style={{ position: "absolute", left: "50%", top: "50%" }}>
      <ReactLoading type="spinningBubbles" color="#09f" width="70px" />
    </div>
  );
}

export default Loading;
