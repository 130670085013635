import React from "react";
import { CommonModal } from "@trica-equity/ui-library.modals.common-modal";
import { FC } from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UserManagementManager from "../../managers/userManagementManager";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import Loader from "../../../loader/loading";
import { toast } from "react-toastify";
import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import ReactQuill from "react-quill";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  type: string;
};

const useStyles = makeStyles({
  rteWrapper: {
    border: "1px solid #f0f2f5",
    borderRadius: 2,
    marginBottom: 20,
  },
  editor: {
    padding: "0 20px",
  },
  noteWrapper: {
    border: "1px solid #f0f2f5",
    borderRadius: 2,
    margin: "10px 0",
    overflowWrap: "break-word",
  },
});

const NoteModal: FC<NoteModalType> = (props) => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const { isOpen, handleClose, selected, type } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [allNote, setAllNote] = useState<any>([]);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };

  const getNoteforUser = async () => {
    try {
      setIsLoading(true);
      const result = await userManagementManager.getNoteForUser(
        selected.id,
        type
      );
      setAllNote(result?.data ?? []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handleAddNote = async () => {
    try {
      setAdding(true);

      let result = await userManagementManager.addNoteForUser(
        selected.id,
        type,
        editorState
      );
      if (result?.data) toast.success("note added !");
      setEditorState("");
      setAdding(false);
      setOpenEditor(false);
      getNoteforUser();
    } catch (err) {
      setAdding(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (selected) {
      getNoteforUser();
    }
  }, [selected]);

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleClose}
      title={`Notes for ${selected?.name} - Awaiting Onboarding`}
      submitText="Close"
      hideCancelButton
      buttonStyles={{ width: "min-width", padding: "0 15px" }}
    >
      <Box width="50vw">
        {openEditor ? (
          <Box>
            <ReactQuill
              theme="snow"
              value={editorState}
              onChange={onEditorStateChange}
            />
            <br />
            <Box display="flex" gap={2}>
              <OutlinedButton onClick={() => setOpenEditor(false)}>
                Cancel
              </OutlinedButton>
              <ActionButton onClick={handleAddNote}>
                {adding ? <Loader /> : "Add"}{" "}
              </ActionButton>
            </Box>
          </Box>
        ) : (
          <OutlinedButton onClick={() => setOpenEditor(true)}>
            Add Note
          </OutlinedButton>
        )}

        <Box mt={3}>
          {isLoading && <Loader />}
          {!isLoading && allNote?.length
            ? allNote.map((item: any, i: number) => (
                <div className={classes.noteWrapper} key={i}>
                  <div
                    style={{
                      padding: 10,
                      background: "#33316f",
                      color: "white",
                      borderRadius: 2,
                    }}
                  >
                    {item?.added_by} note on - {item?.created_at_with_format}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.note }}
                    style={{ padding: 10 }}
                  />
                </div>
              ))
            : null}
        </Box>
      </Box>
    </CommonModal>
  );
};

export default NoteModal;
