export const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "LetsVenture Mail Central",
    navigationButton: false,
  },
};

export const breadCrumbDataMailerLists = {
  stepKey: 2,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: true,
    },
    {
      title: "Mailer Lists",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Mailer Lists",
    navigationButton: true,
  },
};

export const breadCrumbDataCreateList = {
  stepKey: 2,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: true,
    },
    {
      title: "Create Mailer Lsit",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Create Mailer Lsit",
    navigationButton: true,
  },
};

export const breadCrumbDataEditList = {
  stepKey: 2,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: true,
    },
    {
      title: "Edit Mailer Lsit",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Edit Mailer Lsit",
    navigationButton: true,
  },
};

export const breadCrumbDataCampaignsList = {
  stepKey: 2,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: true,
    },
    {
      title: "Campaign Manager",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Campaign Manager",
    navigationButton: true,
  },
};

export const breadCrumbDataNewCampaign = {
  stepKey: 2,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: true,
    },
    {
      title: "New Campaign",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "New Campaign",
    navigationButton: true,
  },
};

export const breadCrumbDataEditCampaign = {
  stepKey: 2,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: true,
    },
    {
      title: "Edit Campaign",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Edit Campaign",
    navigationButton: true,
  },
};

export const NewCampaignTabs: any = [
  {
    name: "SELECT A LIST",
    value: "select_list",
  },
  {
    name: "ABOUT EMAIL",
    value: "about_email",
  },
  {
    name: "TEMPLATE",
    value: "template",
  },
  {
    name: "CONFIRM",
    value: "confirm",
  },
];

export const breadCrumbDataAnalytics = {
  stepKey: 2,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: true,
    },
    {
      title: "Analytics",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Analytics",
    navigationButton: true,
  },
};

export const CampaignListTabs: any = [
  {
    name: "OPEN CAMPAIGNS",
    value: "open_campaigns",
  },
  {
    name: "COMPLETED CAMOAIGNS",
    value: "completed_campaigns",
  },
];

export const USERTYPES: any = [
  {
    value: "investor",
    label: "Investor",
  },
  {
    value: "startup",
    label: "Startup",
  },
];

export const GROUP_TYPES_INVESTOR: any = [
  {
    name: "Only Active Investor",
    value: "only_active",
  },
  {
    name: "All Angel Investor",
    value: "all_angel",
  },
  {
    name: "All Institutions",
    value: "all_institutions",
  },
];

export const GROUP_TYPES_STARTUP: any = [
  {
    name: "All Startups",
    value: "all_startups",
  },
  {
    name: "Investor Connect Startups",
    value: "investor_connect_startups",
  },
];

export const AND_OR: any = [
  { name: "AND", value: "and" },
  { name: "OR", value: "or" },
];

export type SelectListType = {
  selectedList: { label: string; value: string }[] | undefined;
  setSelectedList: (prop: any) => void;
  setSelectedTab: (prop: any) => void;
};

export type AboutEmailType = {
  setSelectedTab: (prop: any) => void;
};

export type TemplateType = {
  setSelectedTab: (prop: any) => void;
};

export const EMAIL_TYPES: any = [
  {
    value: "deal_alerts",
    label: "Deal Alerts",
  },
  {
    value: "introduction",
    label: "Introductions",
  },
  {
    value: "news_and_event_updates",
    label: "News and Event Updates",
  },
];
