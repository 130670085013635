import axios from "axios";

function getHeaders() {
  return { "Content-Type": "application/json" };
}

const handleResponse = (response: any, resolve: any, reject: any) => {
  if (
    !response?.data?.status ||
    typeof response.data.status !== "number" ||
    (response.data.status >= 200 && response.data.status < 300)
  ) {
    resolve(response);
  } else {
    reject(response);
  }
};

export function get(url: string, options: object = {}) {
  return new Promise<any>((resolve, reject) => {
    axios
      .get(url, { headers: getHeaders(), ...options })
      .then((response) => handleResponse(response, resolve, reject))
      .catch((error) => {
        reject(error);
      });
  });
}

export function post(url: string, body: object, options: object = {}) {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(url, body, { headers: getHeaders(), ...options })
      .then((response) => handleResponse(response, resolve, reject))
      .catch((error) => {
        reject(error);
      });
  });
}

export function patch(url: string, body: object, options: object = {}) {
  return new Promise<any>((resolve, reject) => {
    axios
      .patch(url, body, { headers: getHeaders(), ...options })
      .then((response) => handleResponse(response, resolve, reject))
      .catch((error) => {
        reject(error);
      });
  });
}

export function put(url: string, body: object, options: object = {}) {
  return new Promise<any>((resolve, reject) => {
    axios
      .put(url, body, { headers: getHeaders(), ...options })
      .then((response) => handleResponse(response, resolve, reject))
      .catch((error) => {
        reject(error);
      });
  });
}
