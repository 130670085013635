import Loader from "../../../loader/loading";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
} from "@mui/material";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { CommonModal } from "@trica-equity/ui-library.modals.common-modal";
import React, { FC, useEffect, useState } from "react";
import { RadioButton } from "@trica-equity/ui-library.form-elements.radio-button-select";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import {
  InvestorObjType,
  investorType,
} from "../../constants/VERIFY_INVESTOR_CONSTANTS";
import UserManagementManager from "../../managers/userManagementManager";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  handleMoveToAwaiting: () => void;
};

const MoveToAwaitingModal: FC<NoteModalType> = (props) => {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const { isOpen, handleClose, selected, handleMoveToAwaiting } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedin, setLinkedin] = useState("");

  const [countryOptions, setCountryOptions] = useState<any>([]);

  const getCountryOptions = async () => {
    try {
      const result = await userManagementManager.getLookupData("", "countries");
      setCountryOptions(result?.results);
    } catch (e) {
      console.log(e);
    }
  };

  const [selectedCity, setSelectedCity] = useState<any>({});
  const [cityOptions, setCityOptions] = useState<any>([]);

  const [selectedInvest, setSelectedInvest] = useState<any>({});
  const [investOptions, setInvestOptions] = useState<any>([]);

  const [investmentBudget, setInvestmentBudget] = useState("");
  const [expertiesSectors, setExpertiesSectors] = useState("");

  const [selectedNoOfInvest, setSelectedNoOfInvest] = useState<any>({});
  const [noOfInvestOptions, setNoOfInvestOptions] = useState<any>([]);

  const getCityOptions = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData(
        search,
        "locations"
      );
      result = result?.result.data.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setCityOptions(result);
    } catch (e) {
      console.log(e);
    }
  };

  const [individualHaving, setIndividualHaving] = useState({
    fresher: false,
    intermediate: false,
    experience: false,
    noneOfAbove: false,
  });
  const [netWorth2Cr, setNetWorth2Cr] = useState("");
  const [calledInvestor, setCalledInvestor] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    Promise.all([getCountryOptions()]).then(() => {
      setName(selected?.name);
      setEmail(selected?.email);
      setPhone(selected?.mobile);
      setLinkedin(selected?.linkedin_url);
      setSelectedCity({ label: selected?.city, value: selected?.city });
      setCityOptions([
        {
          label: selected?.city,
          value: selected?.city,
        },
      ]);
      setNetWorth2Cr(selected?.netWorth2Cr);
      setIndividualHaving(selected?.individualHaving);
      setIsLoading(false);
    });
  }, [selected]);

  const addNote = async () => {
    try {
      let result = await userManagementManager.addNoteForUser(
        selected.id,
        "User",
        note
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleVerify = async () => {
    await addNote();
  };

  const handleReject = (value: any) => {
    // if (value === "Without Sending Mail") {
    handleMoveToAwaiting();
    handleClose();
    // } else if (value === "With Sending Mail") {
    //   handleOpenRejectWithMail();
    //   handleClose();
    // }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleClose}
      title="Investor Details"
      submitText="Close"
      hideCancelButton
      buttonStyles={{ width: "min-width", padding: "0 15px" }}
    >
      <Box width="50vw">
        <Box mt={3}>
          {isLoading && <Loader />}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              Name
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                name="name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              Email
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                name="email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
            </Grid>

            <Grid item xs={6}>
              Phone
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                name="email"
                value={phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPhone(e.target.value)
                }
              />
            </Grid>

            <Grid item xs={6}>
              Linkedin URL
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                name="email"
                value={linkedin}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLinkedin(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              City
            </Grid>

            <Grid item xs={6}>
              <LvAutoComplete
                label={"Please Select a City"}
                options={cityOptions}
                setOptions={setCityOptions}
                selectedObject={selectedCity}
                setSelectedObject={setSelectedCity}
                onSelect={() => setCityOptions([])}
                onChange={getCityOptions}
              />
            </Grid>

            <Grid item xs={6}>
              Profile Type
            </Grid>

            <Grid item xs={6}>
              {investorType?.find(
                (item: InvestorObjType) => item.value === selected?.profileType
              )?.title ?? "-"}
            </Grid>

            <Grid item xs={6}>
              Invest Today As
            </Grid>

            <Grid item xs={6}>
              <LvAutoComplete
                label={"Please select"}
                options={investOptions}
                setOptions={setInvestOptions}
                selectedObject={selectedInvest}
                setSelectedObject={setSelectedInvest}
                onSelect={() => setInvestOptions([])}
              />
            </Grid>

            <Grid item xs={6}>
              Investment Budget Per Year
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                name="investment_budget"
                value={investmentBudget}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInvestmentBudget(e.target.value)
                }
              />
              <Grid item>Lakh INR</Grid>
            </Grid>

            <Grid item xs={6}>
              Expertise Sectors
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                name="experties_sector"
                value={expertiesSectors}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setExpertiesSectors(e.target.value)
                }
              />
            </Grid>

            <Grid item xs={6}>
              Number of Investments
            </Grid>

            <Grid item xs={6}>
              <LvAutoComplete
                label={"Please select"}
                options={noOfInvestOptions}
                setOptions={setNoOfInvestOptions}
                selectedObject={selectedNoOfInvest}
                setSelectedObject={setSelectedNoOfInvest}
                onSelect={() => {
                  setNoOfInvestOptions([]);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              I am an individual having
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={individualHaving?.fresher} />}
                label="Early stage investment experience"
              />
              <FormControlLabel
                control={<Checkbox checked={individualHaving?.intermediate} />}
                label="Experience as a serial entrepreneur"
              />
              <FormControlLabel
                control={<Checkbox checked={individualHaving?.experience} />}
                label="Senior management professional with at least 10 years of experience"
              />
              <FormControlLabel
                control={<Checkbox checked={individualHaving?.noneOfAbove} />}
                label="None of the above"
              />
            </Grid>

            <Grid item xs={6}>
              Do you have a net worth of more than 2 CR INR apart from primary
              residence?
            </Grid>

            <Grid item xs={6}>
              <RadioButton
                options={[
                  { name: "Yes", value: "yes" },
                  { name: "No", value: "no" },
                ]}
                value={netWorth2Cr}
                onChange={(val: string) => {
                  setNetWorth2Cr(val);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              Called Investor before onboarding?
            </Grid>

            <Grid item xs={6}>
              <RadioButton
                options={[
                  { name: "Yes", value: "yes" },
                  { name: "No", value: "no" },
                ]}
                value={calledInvestor}
                onChange={(val: string) => {
                  setCalledInvestor(val);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Box width="100%">
            <Box width="fit-content" ml="auto">
              <ActionButton>Save</ActionButton>
            </Box>
          </Box>
          <br />
          <Grid container gap={2}>
            <FormLabel>Note</FormLabel>
            <Box width="100%">
              <CustomTextField
                multiline
                minRows={5}
                value={note}
                onChange={(val: any) => setNote(val)}
              />
            </Box>
          </Grid>
          <br />

          <Grid container gap={2}>
            <ActionButton onClick={handleVerify}>Move to Awaiting</ActionButton>
          </Grid>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default MoveToAwaitingModal;
