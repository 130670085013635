import { useState } from "react";
import SideBar from "../../sidebar";
import { Outlet } from "react-router-dom";
import useStyles, { sideMainLayout } from "./styles";

const Home = () => {
  const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = (flag: boolean) => {
    setIsSidebarOpen(flag);
  };
  const onToggle = (flag: boolean) => {
    toggleSidebar(flag);
  };
  return (
    <div>
      <SideBar onToggle={onToggle} />
      <div
        className={
          isSidebarOpen ? classes.homeOnSideBarOpen : classes.homeOnSideBarClose
        }
      >
        <div className={classes.mainBody}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
