import { useState, useEffect } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { IconConstant } from "../../../../constants/IconConstants";
import { NoDataAvailable } from "@trica-equity/ui-library.error.no-data-available";
import StartupManagement from "../../managers/startupManager";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Stack } from "@mui/material";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import { Link } from "react-router-dom";
import { Check } from "@mui/icons-material";

const RejectedTable = (props: any) => {
  const { sortBy, searchBySector, searchByStartup, trigger } = props;
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [page, setPage] = useState(1);

  const getCaDocStatus = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getInitialScreeningDocs(
          "rejected",
          page,

          searchByStartup ?? "",
          sortBy?.value ?? "asc",
          searchBySector?.value ?? ""
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleMoveToAwaiting = async (id: string) => {
    try {
      const result = await startupManagement.initialScreeningAction(id, null);
      toast.success(result?.detail);

      getCaDocStatus();
    } catch (e: any) {
      toast.error(e?.detail);
    }
  };

  const columns = [
    {
      field: "serial_no",
      headerName: "S.NO",
      sortable: false,
      flex: 1,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      sortable: false,
      minWidth: 250,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link to={`/startup/show-startup/${params.row?.id}/`}>
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "applied_on",
      headerName: "Applied On",
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "marked_good",
      headerName: "Marked Good",
      sortable: false,
      flex: 2,
      minWidth: 130,

      renderCell: (params: any) => (params?.row?.marked_good ? <Check /> : ""),
    },
    {
      field: "referred_type_and_by",
      headerName: "Referred Type - Referred By",
      sortable: false,
      minWidth: 250,
    },
    {
      field: "rejected_by",
      headerName: "Rejected By",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 2,
      minWidth: 280,
      renderCell: (params: any) => {
        return (
          <Stack gap={2} direction="row">
            <OutlinedButton
              customStyles={{
                color: getColor("success", 500),
                borderColor: getColor("success", 500),
              }}
              onClick={() => handleMoveToAwaiting(params.row?.id)}
            >
              Move To Awaiting
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  useEffect(() => {
    getCaDocStatus();
  }, [page, trigger]);

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  return apiStatus == API_STATUS.LOADING ? (
    <TablePlaceholder
      rows={6}
      columns={6}
      rowHeight="40px"
      columnHeaderHeight="40px"
    />
  ) : (
    <div>
      <TeComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={10}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
    </div>
  );
};

export default RejectedTable;
