export const investorCommunicationBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Commited Investor Communication",
    navigationButton: false,
  },
};
