export const tabsOptions: any = [
  {
    name: "Paid Transaction",
    value: "paid"
  },
  {
    name: "Pending Transaction",
    value: "pending"
  }
];

export const OrderType: any = [
  {
    label: "Transaction Fess",
    value: "transaction_fee"
  },
  {
    label: "Drawdown",
    value: "drawdown"
  }
];

