import { AuthContext } from "../../../../contexts/authContext";
import UserManagementManager from "../../managers/userManagementManager";
import { Box, FormLabel, Grid } from "@mui/material";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { CommonModal } from "@trica-equity/ui-library.modals.common-modal";
import { useContext } from "react";
import { useState } from "react";
import { FC } from "react";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
};

const RejectWithMail: FC<NoteModalType> = (props) => {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const { isOpen, handleClose, selected } = props;
  const handleReject = () => {
    console.log("rejected");
    handleClose();
  };

  const [message, setMessage] = useState("");
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleReject}
      title="Reject With Message"
      submitText="&nbsp;&nbsp;Reject&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Cancel&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      <Box width="50vw">
        <Grid container gap={2}>
          <FormLabel>Note</FormLabel>
          <Box width="100%">
            <CustomTextField
              multiline
              minRows={5}
              value={message}
              onChange={(val: any) => setMessage(val)}
            />
          </Box>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default RejectWithMail;
