import {
  fetchCrmList,
  fetchCrmData,
  updateCrmTrackerData,
  unlockCommit,
  createPaymentLink,
  commitWithdrawnInfo,
  getPaymentStatus,
  downloadCsvDocument,
} from "../services/commitmentMangementService";

export default class CommitmentManagementManager {
  async getCrmList(type: string) {
    try {
      let result = await fetchCrmList(type);
      let formattedData = [];
      if (result?.data?.success) {
        formattedData = result?.data?.result;
      }
      return { formattedData };
    } catch (error) {
      throw error;
    }
  }

  async getCrmData(type: string, tab: string, data: any) {
    try {
      let result = await fetchCrmData(type, tab, data);
      let formattedData = [];
      if (result?.data?.result?.success) {
        formattedData = result?.data?.result?.data;
      }
      return { formattedData };
    } catch (error) {
      throw error;
    }
  }

  async updateCrmData(data: any) {
    try {
      let result = await updateCrmTrackerData(data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async unlockCommit(investor_commitment_id: any) {
    try {
      let result = await unlockCommit(investor_commitment_id);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async createPaymentLink(
    investor_information_id: string,
    order_type: string,
    order_amount: number | undefined,
    startup_id: string
  ) {
    try {
      let data = {
        investor_information_id,
        order_type,
        order_amount,
        order_note: "Manual created  order",
        startup_id,
      };
      let result = await createPaymentLink(data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async commitWithdrawnInfo(page: number) {
    try {
      let result = await commitWithdrawnInfo(page);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async transactionPaymentStatus(
    page: number,
    startup_id: string,
    status_type: string
  ) {
    try {
      let result = await getPaymentStatus(
        page,
        startup_id,
        status_type,
        "transaction_fee"
      );
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async drawdownPaymentStatus(
    page: number,
    startup_id: string,
    status_type: string
  ) {
    try {
      let result = await getPaymentStatus(
        page,
        startup_id,
        status_type,
        "drawdown"
      );

      return result?.data;
    } catch (error) {
      throw error;
    }
  }
  async downloadCsvDocument(
    page: number,
    startup_id: string,
    payment_status: string,
    order_type: string
  ) {
    try {
      const res = await downloadCsvDocument(
        page,
        startup_id,
        payment_status,
        order_type
      );
      return res;
    } catch (error: any) {
      throw error;
    }
  }
}
