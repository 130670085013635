import {
  getNonSebiInvestors,
  getVerifyingInvestor,
  getNewVerifyingInvestor,
  getLookupData,
  getNoteForUser,
  addNoteForUser,
  getUserDetail,
  adminAction,
  addUser,
  getInvestmentProfile,
  getKycStatusData,
  updateReviewStatus,
  getNewVerifyingPhoneNumber,
  getVerifyingPhoneNumber,
} from "../services/userManagementService";
import { AuthContextProps, UserAuthInfo } from "../../../contexts/authContext";



export default class UserManagementManager {
  private userData: UserAuthInfo;

  constructor(AuthContext: AuthContextProps) {
    this.userData = AuthContext.authState;
  }

  async getVerifyingInvestorAwaiting(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      let result = isNew
        ? await getNewVerifyingInvestor("awaiting", search, page)
        : await getVerifyingInvestor("awaiting", search, page);
      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.signup_date,
        joined: item?.joined ? "Yes" : "No",
        type: item.investor_type,
        location: isNew
          ? item?.city[0]?.label + " , " + item?.citizenship_country[0]?.label
          : item.city,
        profileType: item?.join_user_info?.describes_your_profile,
        netWorth2Cr: item?.join_user_info?.net_worth ? "yes" : "no",
        individualHaving: {
          fresher: !!item?.join_user_info?.individual_statement_fresher,
          intermediate:
            !!item?.join_user_info?.individual_statement_intermediate,
          experience: !!item?.join_user_info?.individual_statement_experience,
          noneOfAbove: !(
            !!item?.join_user_info?.individual_statement_fresher ||
            !!item?.join_user_info?.individual_statement_intermediate ||
            !!item?.join_user_info?.individual_statement_experience
          ),
        },
        country: item?.join_user_info?.citizenship_country,
        mobile: isNew ? item?.phone_no : item?.mobile,
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getVerifyingInvestorVerified(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      let result = isNew
        ? await getNewVerifyingInvestor("verified", search, page)
        : await getVerifyingInvestor("verified", search, page);
      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.approval_date,
        type: item.investor_type,
        joined: item?.joined ? "Yes" : "No",
        manager: item.relationship_manager,
        phone_no: isNew ? item?.phone_no : item.users_phone,
        location: isNew
          ? item?.city[0]?.label + " , " + item?.citizenship_country[0]?.label
          : item.city,
        outbound_user: item?.outbound ? "Yes" : "No",
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getVerifyingInvestorRejected(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      let result = isNew
        ? await getNewVerifyingInvestor("rejected", search, page)
        : await getVerifyingInvestor("rejected", search, page);
      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.approval_date,
        joined: item?.joined ? "Yes" : "No",
        manager: item.relationship_manager,
        phone_no: isNew ? item?.phone_no : item.users_phone,
        location: isNew
          ? item?.city[0]?.label + " , " + item?.citizenship_country[0]?.label
          : item.city,
        type: item.investor_type,
        outbound_user: item?.outbound ? "Yes" : "No",
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getNonSebiInvestors(search: string, page: number) {
    try {
      // let result = await getNonSebiInvestors(search, page);
      let result = {
        "count": 2,
        "next": "https://api.staging.letsventure.com/v1/admin/user_management/new_verify_investor/?mode=awaiting&page=2&search_text=",
        "previous": null,
        "results": [
          {
            "id": 3416,
            "date": "2023-08-07",
            "name": "Kashee Kushwaha",
            "email": "kasheeram.kushwaha+aug07@letsventure.com",
            "mobile": "+919179807380",
            "linkedin_profile": "https://LinkedIn.com/in/kashee",
            "city": "Bengaluru, India",
            "onboard": "angel_investor",
            "profile_type": "professional",
            "join_user_info": {
              "invest_today": null,
              "investment_budget_per_year": 0,
              "expertise_sectors": [
                
              ],
              "no_of_investments": null,
              "individual_statement_fresher": true,
              "individual_statement_intermediate": false,
              "individual_statement_experience": false,
              "none_of_the_above": false,
              "experience": null,
              "institution_name": null,
              "designation": null,
              "institution_url": null,
              "institution_assets_worth": null,
              "net_worth": 0,
              "called_investor": null
            }
          },
          {
            "id": 3279,
            "date": "2023-05-25",
            "name": "Avinash Singh",
            "email": "avinash.singh+may258@letsventure.com",
            "mobile": "+919584754016",
            "linkedin_profile": "https://www.linkedin.com/in/avinash-pratap-singh-13b37a147/",
            "city": "Gwalior, India",
            "onboard": "angel_investor",
            "profile_type": "business_owner",
            "join_user_info": {
              "invest_today": null,
              "investment_budget_per_year": 0,
              "expertise_sectors": [
                
              ],
              "no_of_investments": null,
              "individual_statement_fresher": false,
              "individual_statement_intermediate": false,
              "individual_statement_experience": false,
              "none_of_the_above": true,
              "experience": null,
              "institution_name": null,
              "designation": null,
              "institution_url": null,
              "institution_assets_worth": null,
              "net_worth": 0,
              "called_investor": null
            }
          },
          
        ]
      }
      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        // date: item.approval_date,
        // manager: item.relationship_manager,
        // phone_no: item.users_phone,
        location: item.city,
        onboarding_as: item.onboard,
        // type: item.investor_type,
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }

  async getLookupData(
    search: string,
    type: string,
    user_search_private_hashtag?: string
  ) {
    try {
      let result = await getLookupData(
        search,
        type,
        user_search_private_hashtag
      );

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async getNoteForUser(id: string, type: string) {
    try {
      let result = await getNoteForUser(id, type);
      return { data: result.data.results };
    } catch (error) {
      throw error;
    }
  }

  async addNoteForUser(id: string, type: string, note: string) {
    try {
      let result = await addNoteForUser(id, type, note);
      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async getUserDetail(userId: string) {
    try {
      let result = await getUserDetail(userId);
      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async adminAction(userId: string, todo: string, body: any) {
    try {
      let result = await adminAction(userId, todo, body);
      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async addUser(
    name: string,
    email: string,
    addPromotion: boolean,
    onboardingFees: number,
    upfrontDrawdown: number,
    placementFees: number
  ) {
    try {
      const formData = new FormData();

      if (addPromotion) {
        formData.append("name", name);
        formData.append("email", email);
        formData.append("onboarding_fee", onboardingFees.toString());
        formData.append("upfront_drawdown", upfrontDrawdown.toString());
        formData.append("placement_fee", placementFees.toString());
      } else {
        formData.append("name", name);
        formData.append("email", email);
      }

      let result = await addUser(formData);
      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async getInvestmentProfile(search: string, investor_id: string) {
    try {
      let result = await getInvestmentProfile(search, investor_id);

      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async getKycStatusData(
    page: number,
    investor_id: string,
    stage: string,
    onboarding_complete: string
  ) {
    try {
      let onBoardComplete =
        onboarding_complete === "yes"
          ? true
          : onboarding_complete === "no"
          ? false
          : "not_provided";
      let result = await getKycStatusData(
        page,
        investor_id,
        stage,
        onBoardComplete
      );

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateReviewStatus(
    recordId: string,
    ckyc_no: string,
    comments: string,
    kra_id: string,
    kra_matches: boolean,
    kyc_matches: boolean,
    lv_review_stage: string
  ) {
    try {
      let data = {
        ckyc_no,
        comments,
        kra_id,
        kra_matches,
        kyc_matches,
        last_updated_by_user_id: `${this.userData.user.id}`,
        lv_review_stage,
      };
      let result = await updateReviewStatus(recordId, data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

 /** Verify Phone Number*/
  async getVerifyingPhoneAwaiting(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      // let result = isNew
      //   ? await getNewVerifyingPhoneNumber("awaiting", search, page)
      //   : await getVerifyingPhoneNumber("awaiting", search, page);

      let result = {
        "count": 2,
        "next": "https://api.staging.letsventure.com/v1/admin/user_management/new_verify_investor/?mode=awaiting&page=2&search_text=",
        "previous": null,
        "results": [
          {
            "id": 57,
            "date": "2024-04-12",
            "name": "Jogendra Majhi",
            "email": "jogendra.majhi+apr11i@letsventure.com",
            "mobile": "+6568696764",
            "type": "Investor",
            "startup": [
              
            ]
          },
          {
            "id": 45,
            "date": "2024-04-11",
            "name": "Startup Jogendra apr 10 f",
            "email": "jogendra.majhi+startupapr11c@letsventure.com",
            "mobile": "+6565859645",
            "type": "Startup",
            "startup": [
              {
                "id": 10670,
                "name": "Startup Apr 10 b",
                "permalink": "StartupApr10b_3",
                "summary": null,
                "startup_profile_image": {
                  "url": "https://backstage.letsventure.com/assets/fallback/startup-44795c9d511625f90532f91a469ea441aad6b0eb7d9bc35dc0a39a10d14352a4.png",
                  "thumb": {
                    "url": "https://backstage.letsventure.com/assets/fallback/thumb_startup-095d438a1885572bf86c85d966b1aba9b5182427930d79e1b79b0c3c50efa795.png"
                  }
                }
              }]
            }
        ]
      }
     

      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        date: item.date,
        name: item.name,
        email: item?.email,
        mobile: item.mobile,
        type: item.type,
        startup: item?.startup[0]?.name || '-',

      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }

  async getVerifyingPhoneVerified(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      // let result = isNew
      //   ? await getNewVerifyingPhoneNumber("verified", search, page)
      //   : await getVerifyingPhoneNumber("verified", search, page);
      let result = {
        "count": 2,
        "next": "https://api.staging.letsventure.com/v1/admin/user_management/new_verify_investor/?mode=awaiting&page=2&search_text=",
        "previous": null,
        "results": [
          {
            "id": 57,
            "date": "2024-04-12",
            "name": "Jogendra Majhi",
            "email": "jogendra.majhi+apr11i@letsventure.com",
            "mobile": "+6568696764",
            "type": "Investor",
            "startup": [
              
            ]
          },
          {
            "id": 45,
            "date": "2024-04-11",
            "name": "Startup Jogendra apr 10 f",
            "email": "jogendra.majhi+startupapr11c@letsventure.com",
            "mobile": "+6565859645",
            "type": "Startup",
            "startup": [
              {
                "id": 10670,
                "name": "Startup Apr 10 b",
                "permalink": "StartupApr10b_3",
                "summary": null,
                "startup_profile_image": {
                  "url": "https://backstage.letsventure.com/assets/fallback/startup-44795c9d511625f90532f91a469ea441aad6b0eb7d9bc35dc0a39a10d14352a4.png",
                  "thumb": {
                    "url": "https://backstage.letsventure.com/assets/fallback/thumb_startup-095d438a1885572bf86c85d966b1aba9b5182427930d79e1b79b0c3c50efa795.png"
                  }
                }
              }]
            }
        ]
      }
     
      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        date: item.date,
        name: item.name,
        email: item?.email,
        mobile: item.mobile,
        type: item.type,
        startup: item?.startup[0]?.name || '-',
        
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }
}
