import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useStyles } from "../../../userManagement/components/usersPanel/styles";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { Formik } from "formik";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

const StartSchemeGeneration = () => {
  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <Formik
          initialValues={{ leadcarry: 0, round_type: "" }}
          onSubmit={() => {}}
        >
          <>
            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Select Startup</Typography>
              </Grid>
              <Grid item xs={8}>
                <LvAutoComplete
                  label="Select Startup"
                  options={option}
                  setOptions={setOption}
                  selectedObject={selectedVal}
                  setSelectedObject={setSelectedVal}
                  // onChange={handleTextChange}
                />
              </Grid>
            </Stack>
            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Total Lead Carry</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="leadcarry"
                  id="leadcarry"
                  name="leadcarry"
                  formName="leadcarry"
                />
              </Grid>
            </Stack>
            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Round Type</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="round_type"
                  id="round_type"
                  name="round_type"
                  formName="round_type"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Hurdle rate</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="hurdle_rate"
                  id="hurdle_rate"
                  name="hurdle_rate"
                  formName="hurdle_rate"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Transaction Fees</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="transaction_fees"
                  id="transaction_fees"
                  name="transaction_fees"
                  formName="transaction_fees"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Fund carry</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="fund_carry"
                  id="fund_carry"
                  name="fund_carry"
                  formName="fund_carry"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Deal Progress on LV</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="deal_progress"
                  id="deal_progress"
                  name="deal_progress"
                  formName="deal_progress"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Total fundraising</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="total_fundraising"
                  id="total_fundraising"
                  name="total_fundraising"
                  formName="total_fundraising"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Pre-money Valuation</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="pmv"
                  id="pmv"
                  name="pmv"
                  formName="pmv"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Price per share/ unit of the security</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="price_per_share"
                  id="price_per_share"
                  name="price_per_share"
                  formName="price_per_share"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>
                  Portfolio Entity profile and product details/services offered
                  by the Portfolio Entity
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="portfolio_entity_profile"
                  id="portfolio_entity_profile"
                  name="portfolio_entity_profile"
                  formName="portfolio_entity_profile"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>
                  Number of securities along with description
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="number_of_securities"
                  id="number_of_securities"
                  name="number_of_securities"
                  formName="number_of_securities"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>
                  Conversion Price and terms, in case convertible securities
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="conversion_price_terms"
                  id="conversion_price_terms"
                  name="conversion_price_terms"
                  formName="conversion_price_terms"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>Name of the Investment Scheme</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="investment_scheme_name"
                  id="investment_scheme_name"
                  name="investment_scheme_name"
                  formName="investment_scheme_name"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>
                  Rights under Negotiation for this Scheme
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="right_under_negotiation"
                  id="right_under_negotiation"
                  name="right_under_negotiation"
                  formName="right_under_negotiation"
                />
              </Grid>
            </Stack>

            <Stack direction="row" gap={3} mb={3}>
              <Grid item xs={4}>
                <Typography>
                  Last date to provide consent to participate in the Investment
                  Scheme
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomTextField
                  fieldName="last_date_consent"
                  id="last_date_consent"
                  name="last_date_consent"
                  formName="last_date_consent"
                />
              </Grid>
            </Stack>
            <Box marginLeft="auto" width="fit-content">
              <Stack direction="row" gap={2}>
                <ActionButton>Save</ActionButton>
                <ActionButton>Generate</ActionButton>
              </Stack>
            </Box>
          </>
        </Formik>
      </Grid>
    </Grid>
  );
};

export default StartSchemeGeneration;
