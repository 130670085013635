import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/v1/admin";
const API_BASE_URL2 = "https://api.staging.letsventure.com/v1/";

// const API_BASE_URL = 'http://localhost:8000/v1/admin';

export const fetchCrmList = (type: string) => {
  const finalURL =
    API_BASE_URL +
    `/commitment_management/commit_tracker/fetch_crm_list/?type=${type}`;
  return Axios.get(finalURL);
};

export const fetchCrmData = (type: string, tab: string, data: any) => {
  const params =
    type === "startups"
      ? `startup_id=${data.value}`
      : `investor_id=${data.investor_id}&investor_type=${data.investor_type}`;
  const finalURL =
    API_BASE_URL +
    `/commitment_management/commit_tracker/fetch_crm_data/?type=${type}&tab=${tab}&${params}`;
  return Axios.get(finalURL);
};

export const updateCrmTrackerData = (data: any) => {
  const finalURL =
    API_BASE_URL + `/commitment_management/commit_tracker/update_crm_data/`;
  return Axios.post(finalURL, data);
};

export const unlockCommit = (investor_commitment_id: any) => {
  const finalURL =
    API_BASE_URL +
    `/commitment_management/commit_tracker/unlock_commit/?investor_commitment_id=${investor_commitment_id}`;
  return Axios.put(finalURL, {});
};

export const createPaymentLink = (data: any) => {
  const finalURL = API_BASE_URL2 + `order/`;
  return Axios.post(finalURL, data);
};
export const commitWithdrawnInfo = (page: number) => {
  const finalURL =
    API_BASE_URL + `/commitment_management/commit_withdrawn_info/?page=${page}`;
  return Axios.get(finalURL);
};

export const getPaymentStatus = (
  page: number,
  startup_id: string,
  status_type: string,
  order_type: string
) => {
  const finalURL =
    API_BASE_URL2 +
    `investor_payments/?page=${page}&startup_id=${startup_id}&payment_status=${status_type}&order_type=${order_type}`;
  return Axios.get(finalURL);
};

export const downloadCsvDocument = (
  page: number,
  startup_id: string,
  payment_status: string,
  order_type: string
) => {
  let finalURL =
    API_BASE_URL2 +
    `investor_payments/download_csv/?page=${page}&payment_status=${payment_status}&order_type=${order_type}`;

  if (startup_id) {
    finalURL += `&startup_id=${startup_id}`;
  }
  return Axios.get(finalURL);
};
