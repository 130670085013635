import moment, { Moment } from "moment";

export function getFormattedDate(
  date: string,
  format = "MMM DD, YYYY",
  options: { utc?: boolean } | null | undefined = { utc: true }
) {
  if (!date) {
    return "";
  }
  return options?.utc
    ? moment.utc(date).format(format)
    : moment(date).format(format);
}
