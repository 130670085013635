import { Box, Grid, Stack, Typography } from "@mui/material";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import React, { FC, useState } from "react";
import {
  SelectListType,
  USERTYPES,
} from "../../../constants/mailCentralConstant/MailCentralConstant";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";

const SelectList: FC<SelectListType> = (props) => {
  const { selectedList, setSelectedList, setSelectedTab } = props;

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={8}>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Select a List</Typography>
            </Grid>
            <Grid item xs={8}>
              <CoreDropdown
                options={USERTYPES}
                label="Select List"
                value={selectedList}
                onClickMenuItem={(option: any) => {
                  setSelectedList(option);
                }}
              />
            </Grid>
          </Stack>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Receipients Count</Typography>
            </Grid>
            <Grid item xs={8}>
              2
            </Grid>
          </Stack>
          <ActionButton
            badgeStyles={{
              display: "block",
              width: "fit-content",
              marginLeft: "auto",
            }}
            onClick={() => setSelectedTab("about_email")}
          >
            Next
          </ActionButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectList;
