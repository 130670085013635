import { useState, useCallback } from "react";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import CaDocPaymentTable from "./CaDocPaymentTable";
import { HorizontalTabs } from "@trica-equity/ui-library.tabs.horizontal-tabs";
import { Box, Grid } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import CommitmentManagementManager from "../../../commitmentManagement/managers/commitmentManagementManager";
import { toast } from "react-toastify";
import { CsvFilename } from "../../../../constants/commonConstants";

export default function CaDocPayment() {
  const commitmentManager = new CommitmentManagementManager();

  const [selectedTab, setSelectedTab] = useState("paid");
  const [page, setPage] = useState(1);

  const [downloading, setDownloading] = useState(false);

  const handleDownloadDocument = async () => {
    try {
      setDownloading(true);
      const result: any = await commitmentManager.downloadCsvDocument(
        page,
        "",
        selectedTab,
        "aif"
      );
      var a = document.createElement("a");
      a.href = "data:attachment/csv;charset=utf-8," + encodeURI(result.data);
      a.target = "_blank";
      a.download = `${CsvFilename.AIF}.csv`;
      document.body.appendChild(a);
      a.click();
      setDownloading(false);
    } catch (e: any) {
      toast.error(e);
      setDownloading(false);
    }
  };

  const tabs = [
    {
      name: "Paid User",
      value: "paid",
      content: (
        <CaDocPaymentTable
          status={selectedTab}
          page={page}
          setPage={setPage}
          type="paid"
        />
      ),
    },
    {
      name: "Unpaid Users",
      value: "pending",
      content: (
        <CaDocPaymentTable
          status={selectedTab}
          page={page}
          setPage={setPage}
          type="pending"
        />
      ),
    },
  ];

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "AIF Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "CA Doc Online Payment Status",
      navigationButton: false,
    },
  };

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="end">
          <ActionButton onClick={handleDownloadDocument} disabled={downloading}>
            {downloading ? "Downloading CSV" : "Download CSV"}
          </ActionButton>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
        xAxisPadding={false}
        customTabsStyles={{
          width: "100%",
          height: "44px",
        }}
        customTabStyles={{
          fontFamily: "Work Sans",
          lineHeight: "21px",
          textAlign: "center",
          letterSpacing: "0.5px",
          fontStyle: "normal",
        }}
      />
    </Box>
  );
}
