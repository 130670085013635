import { USER_ROLES } from "../../constants/appConstants";
import { Outlet } from "react-router-dom";
import FinalScreening from "../../app/startupManagement/components/finalScreening";
import CommittedInvestorCommunication from "../../app/startupManagement/components/committedInvestorCommunication";
import InitialScreening from "../../app/startupManagement/components/initialScreening";
import ShowStartups from "../../app/startupManagement/components/showStartups";
import StartupPanel from "../../app/startupManagement/components/startupPanel";
import AddUser from "../../app/userManagement/components/addUser";
import KycStatus from "../../app/userManagement/components/kycStatus";
import NewVerifyInvestor from "../../app/userManagement/components/newVerifyInvestor";
import ProfilePendingInvestor from "../../app/userManagement/components/profilePendingInvestor";
import UsersPanel from "../../app/userManagement/components/usersPanel";
import ShowUser from "../../app/userManagement/components/showUser";
import CommitTracker from "../../app/commitmentManagement/components/commit_tracker/page";
import PostCommitStatusTracker from "../../app/commitmentManagement/components/post_commit_status/page";
import CommitWithdrawInfo from "../../app/commitmentManagement/components/commit_withdraw_info/page";
import FeePaymentStatus from "../../app/commitmentManagement/components/fee_payment_status/page";
import DrawdownPaymentStatus from "../../app/commitmentManagement/components/drawdown_payment_status/page";
import CreatePaymentLink from "../../app/commitmentManagement/components/create_payment_link/page";
import DealDocuments from "../../app/documentManagement/components/dealDocuments";
import Rsvp from "../../app/campaignManagement/components/rsvp";
import CaDocaManagement from "../../app/aifManagement/components/caDocManagement";
import CaDocStatus from "../../app/aifManagement/components/caDocStatus";
import CaDocPayment from "../../app/aifManagement/components/caDocPaymentStatus";
import SchemeDocManagement from "../../app/aifManagement/components/schemeDocManagement";
import CreateUpdateSyndicate from "../../app/syndicateManagement/components/createUpdateSyndicate";
import CreateStartup from "../../app/syndicateManagement/components/createStartup";
import NonSebiInvestor from "../../app/userManagement/components/nonSebiInvestor";
import InvestorInformation from "../../app/dashboard/components/investorInformation";
import InstitutionsPanel from "../../app/institutionsManagement/components/institutionsPanel";
import ShowInstitution from "../../app/institutionsManagement/components/showInstitution";
import InvestorDocuments from "../../app/documentManagement/components/investorDocuments";
import MailCentral from "../../app/campaignManagement/components/mailCentral";
import MailerLists from "../../app/campaignManagement/components/mailCentral/mailerLists";
import CampaignsList from "../../app/campaignManagement/components/mailCentral/campaignsList";
import MailerAnalytics from "../../app/campaignManagement/components/mailCentral/mailerAnalytics";
import VerifyPhoneNumber from "../../app/userManagement/components/verifyPhoneNumber";
import NomineeInformation from "../../app/dashboard/components/nomineeInformation";
import DematInformation from "../../app/dashboard/components/dematInformation";
import CreateMailerList from "../../app/campaignManagement/components/mailCentral/createMailerList";
import NewCampaign from "../../app/campaignManagement/components/mailCentral/newCampaign";

export const AdminRoutes = () => [
  {
    label: "Dashboard",
    value: "dashboard",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "dashboard",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Investor Information",
        value: "investor_information",
        link: "investor-information",
        component: <InvestorInformation />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Nominee Information",
        value: "nominee_information",
        link: "nominee-information",
        component: <NomineeInformation />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Demat Information",
        value: "demat_information",
        link: "demat-information",
        component: <DematInformation />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage User",
    value: "user_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "user",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "New Verify Investor",
        value: "new_verify_investor",
        link: "new-verify-investor",
        component: <NewVerifyInvestor />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Verify Phone Number",
        value: "verify_phone_number",
        link: "verify-phone-number",
        component: <VerifyPhoneNumber />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "User's Panel",
        value: "users_panel",
        link: "users-panel",
        component: <UsersPanel />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Show User",
        value: "show_user",
        link: "show-user/:id",
        component: <ShowUser />,
        hide: true,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Add User",
        value: "add_user",
        link: "add-user",
        component: <AddUser />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Non SEBI Investor",
        value: "non_sebi_investor",
        link: "non-sebi-investor",
        component: <NonSebiInvestor />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Profile Pending Investor",
        value: "profile_pending_investor",
        link: "profile-pending-investor",
        component: <ProfilePendingInvestor />,
        roles: [USER_ROLES.ADMIN],
      },

      {
        label: "User KYC Status",
        value: "kyc_status",
        link: "kyc-status",
        component: <KycStatus />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage Institution",
    value: "institutions_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "institution",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Institution's Panel",
        value: "institution_panel",
        link: "institution-panel",
        component: <InstitutionsPanel />,
        roles: [USER_ROLES.ADMIN],
      },

      {
        label: "Show Institution",
        value: "show_institution",
        link: "show-institution/:id",
        component: <ShowInstitution />,
        hide: true,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage Startup",
    value: "startup_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "startup",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Startup Panel",
        value: "startup_panel",
        link: "startup-panel",
        component: <StartupPanel />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Show Startup",
        value: "show_startup",
        link: "show-startup/:startup_id",
        component: <ShowStartups />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Initial Screening",
        value: "initial_screening",
        link: "initial-screening",
        component: <InitialScreening />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Final Screening",
        value: "final_screening",
        link: "final-screening",
        component: <FinalScreening />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Investor Communication",
        value: "committed_investor_communication",
        link: "committed-investor-communication",
        component: <CommittedInvestorCommunication />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage Syndicate",
    value: "syndicate_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "syndicate",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Create/Update Syndicate",
        value: "create_update_syndicate",
        link: "create-update-syndicate",
        component: <CreateUpdateSyndicate />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Create Startup",
        value: "create_startup",
        link: "create-startup",
        component: <CreateStartup />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage Commitment",
    value: "commitment_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "commitment",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Commit Tracker",
        value: "commit_tracker",
        link: "commit-tracker",
        component: <CommitTracker />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Post Commit Status",
        value: "post_commit_status",
        link: "post-commit-status",
        component: <PostCommitStatusTracker />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Commit Withdraw Info",
        value: "commit_withdraw_info",
        link: "commit-withdraw-info",
        component: <CommitWithdrawInfo />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Transaction Payment Status",
        value: "fee_payment_status",
        link: "fee-payment-status",
        component: <FeePaymentStatus />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Drawdown Payment Status",
        value: "drawdown_payment_status",
        link: "drawdown-payment-status",
        component: <DrawdownPaymentStatus />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Create Payment Link",
        value: "create_payment_link",
        link: "create-payment-link",
        component: <CreatePaymentLink />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage AIF",
    value: "aif_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "aif",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "CA Doc Management",
        value: "ca_doc_management",
        link: "ca-doc-management",
        component: <CaDocaManagement />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "CA Doc Status",
        value: "ca_doc_status",
        link: "ca-doc-status",
        component: <CaDocStatus />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "CA Doc Payment Status",
        value: "ca_doc_online_payment_status",
        link: "ca-doc-online-payment-status",
        component: <CaDocPayment />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Scheme Doc Management",
        value: "scheme_doc_management",
        link: "scheme-doc-management",
        component: <SchemeDocManagement />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage Document",
    value: "document_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "document",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Investor Documents",
        value: "investor_documents",
        link: "investor-documents",
        component: <InvestorDocuments />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Deal Documents",
        value: "deal_documents",
        link: "deal-documents",
        component: <DealDocuments />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "Manage Campaign",
    value: "campaign_management",
    icon: "https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg",
    link: "campaign",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "RSVP",
        value: "rsvp",
        link: "rsvp",
        component: <Rsvp />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Mail Central",
        value: "mail_central",
        link: "mail-central",
        component: <MailCentral />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Mailer Lists",
        value: "mailer_lists",
        link: "mailer-lists",
        component: <MailerLists />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Create Mailer List",
        value: "create_mailer_list",
        link: "create-mailer-list",
        component: <CreateMailerList />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Edit Mailer List",
        value: "edit_mailer_list",
        link: "edit-mailer-list/:list_id",
        component: <CreateMailerList />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Campaigns List",
        value: "campaigns_list",
        link: "campaigns-list",
        component: <CampaignsList />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "New Campaign",
        value: "new_campaign",
        link: "new-campaign",
        component: <NewCampaign />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Edit Campaign",
        value: "edit_campaign",
        link: "edit-campaign/:campaign_id",
        component: <NewCampaign />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Mailer Analytics",
        value: "mailer_analytics",
        link: "mailer-analytics",
        component: <MailerAnalytics />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
    ],
  },
];
