import { Box, Grid } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { useState } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import StartupManagement from "../../managers/startupManager";
import { toast } from "react-toastify";
import { useEffect } from "react";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import StartupUpdateModal from "./modals/StartupUpdateModal";
import { investorCommunicationBreadCrumbData } from "../../constants/InvestorCommunicationConstants";

export default function addNewInstitution() {
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [isStartupUpdateModalOpen, setIsStartupUpdateModalOpen] =
    useState(false);
  const [selected, setSelected] = useState<any>();

  //   const getSectorOption = async () => {
  //     try {
  //       const result = await userManagementManager.getLookupData(
  //         "",
  //         "horizontal_sector"
  //       );
  //       let options = result?.results?.map((item: any) => ({
  //         label: item?.text,
  //         value: item?.id,
  //       }));
  //       setSectorOption(options);
  //     } catch (e: any) {
  //       toast.error(e);
  //     }
  //   };

  const getCommittedInvestorCommunication = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getCommitmentInvestorCommunication(page);
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handlePublish = async (id: string) => {
    try {
      const result = await startupManagement.finalScreeningAction(id, true);

      toast.success(result?.detail);
      getCommittedInvestorCommunication();
    } catch (e: any) {
      toast.error(e?.detail);
    }
  };

  const handleUnPublish = async (id: string) => {
    try {
      const result = await startupManagement.finalScreeningAction(id, false);
      toast.success(result?.detail);
      //   getCaDocStatus();
    } catch (e: any) {
      toast.error(e);
      toast.error(e?.detail);
    }
  };

  const handleCloseStartupUpdateModal = () => {
    setIsStartupUpdateModalOpen(false);
  };
  const handleOpenStartupUpdateModal = () => {
    setIsStartupUpdateModalOpen(true);
  };

  const Columns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      sortable: false,
      minWidth: 180,
      flex: 2,
      renderCell: (params: any) => {
        // const actionData = params.row.action;
        // return (
        //   <Link to={`/startup/show-startup/${params.row?.id}/`}>
        //     {params?.row?.name}{" "}
        //   </Link>
        // );
      },
    },
    {
      field: "update_type",
      headerName: "Update Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email_subject",
      headerName: "Email Subject",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "email_content",
      headerName: "Email Content",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "attachements",
      headerName: "Attachments",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "submitted_date",
      headerName: "Submitted Date",
      flex: 2,
      minWidth: 150,
      //   renderCell: (params: any) => (params?.row?.mou_uploaded ? <Check /> : ""),
    },

    {
      field: "action",
      headerName: "Publish/Unpublish",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 200),
              borderColor: getColor("action_button", 200),
            }}
            onClick={() => {
              setSelected(params.row);
              alert(`Startup update ${params.row.action} successfully.`);
            }}
          >
            {params.row.action}
          </OutlinedButton>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  useEffect(() => {
    getCommittedInvestorCommunication();
  }, [page]);
  //   useEffect(() => {
  //     getSectorOption();
  //   }, []);

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Breadcrumb
            steps={investorCommunicationBreadCrumbData?.steps}
            pageHeader={investorCommunicationBreadCrumbData.pageHeader}
            stepKey={investorCommunicationBreadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={7} display="flex" justifyContent="end">
          <ActionButton
            onClick={() => {
              handleOpenStartupUpdateModal();
            }}
          >
            Create Startup Update
          </ActionButton>
        </Grid>
      </Grid>

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <TeComplexTable
            rows={formattedData}
            columns={Columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}

      <StartupUpdateModal
        isOpen={isStartupUpdateModalOpen}
        handleClose={handleCloseStartupUpdateModal}
        selected={null}
        handleOpenRejectWithoutMail={() => {}}
        handleOpenRejectWithMail={() => {}}
      />
    </Box>
  );
}
