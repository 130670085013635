import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import "./loginStyles.css";
import { useAuth0 } from "@auth0/auth0-react";

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="app">
      <div className="login">
        <div className="formElement">
          <span className="heading">
            Sign in to &nbsp;
            <img
              alt="lv_logo"
              width="180"
              height="32"
              src={
                "https://letsventure.s3.ap-southeast-1.amazonaws.com/letsventure/LetsVenture-Colorlogo.svg"
              }
            />
          </span>
        </div>

        {/* <Box className="formElement" component="a"> */}
        <ActionButton
          customStyles={{
            width: "100%",
            padding: "25px",
            borderRadius: "15px",
            backgroundColor: "#2563C9",
            textTransform: "none",
            fontWeight: "bold",
          }}
          onClick={loginWithRedirect}
        >
          Sign In &gt;
        </ActionButton>
        {/* </Box> */}
      </div>
    </div>
  );
}
