import { Help } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import React, { useEffect, useState } from "react";
import { FileUpload } from "@trica-equity/ui-library.form-elements.file-upload";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import SyndicateManagement from "../../managers/syndicateManagementManager";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { SyndicateTypeOptions } from "./CreateSyndicate";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

const UpdateSyndicate = () => {
  const authUser = useContext(AuthContext);
  const syndicateManagementManager = new SyndicateManagement(authUser);
  const userManagementManager = new UserManagementManager(authUser);

  const [updating, setUpdating] = useState(false);

  const [selectedSyndicate, setSelectedSyndicate] = useState<any>({});
  const [syndicateOptions, setSyndicateOptions] = useState<any>([]);
  const [syndicateType, setSyndicateType] = useState<any>(
    SyndicateTypeOptions[0].value
  );

  const [isPvtSynd, setIsPvtSynd] = useState(false);
  const [isTopSynd, setIsTopSynd] = useState(false);
  const [name, setName] = useState("");
  const [syndicateHeader, setSyndicateHeader] = useState("");
  const [syndicateAbout, setSyndicateAbout] = useState("");
  const [investmentThesis, setInvestmentThesis] = useState("");
  const [mininvestment, setMinInvestment] = useState(0);
  const [maxInvestment, setMaxInvestment] = useState(0);
  const [dealPerYear, setDealPerYear] = useState(0);
  const [profileImage, setProfileImage] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [loading, setLoading] = useState(false);

  const [maxCorpus, setMaxCorpus] = useState(0);
  const [preCommitTenure, setPreCommitTenure] = useState(0);
  const [managementFee, setManagementFee] = useState(0);
  const [drawdownNo, setDrawdownNo] = useState(0);
  const [drawdownInterval, setDrawdownInterval] = useState(0);
  const [lvCarry, setLvCarry] = useState(0);
  const [hurdleRate, setHurdleRate] = useState(0);

  const searchSyndicate = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData(
        search,
        "syndicate"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setSyndicateOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const [selectedMailer, setSelectedMailer] = useState<any>({});
  const [mailerOptions, setMailerOptions] = useState<any>([]);

  const getMailersOptions = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData(
        search,
        "mailer_list"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setMailerOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const getSyndicateDetail = async () => {
    try {
      setLoading(true);
      const result = await syndicateManagementManager.getSyndicateDetail(
        selectedSyndicate?.value
      );
      setName(result?.name);
      setSyndicateHeader(result?.header);
      setSyndicateAbout(result?.description);
      setInvestmentThesis(result?.investment_thesis);
      setMinInvestment(result?.min_typical_investment);
      setMaxInvestment(result?.max_typical_investment);
      setDealPerYear(result?.deal_per_year);
      setSyndicateType(result?.syndicate_type);
      setLeadCarryTableData(result?.syndicate_lead_carry_arr);

      setMaxCorpus(result?.syndicate_corpus);
      setPreCommitTenure(result?.pre_commit_tenure);
      setManagementFee(result?.management_fee);
      setDrawdownNo(result?.no_of_drawdowns);
      setDrawdownInterval(result?.drawdown_interval);
      setLvCarry(result?.lv_carry);
      setHurdleRate(result?.hurdle_rate);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      toast.error(e);
    }
  };

  const [selectedLead, setSelectedLead] = useState<any>({});
  const [leadOptions, setLeadOptions] = useState<any>([]);
  const getLeadOptions = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData(
        search,
        "mailer_list"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      // setMailerOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const [leadCarry, setLeadCarry] = useState(0);
  const [leadCarryTableData, setLeadCarryTableData] = useState([]);

  const handleDeleteLeadCarry = async (id: any) => {
    try {
      const result = await syndicateManagementManager.deleteSyndicateLeadCarry(
        selectedSyndicate?.value,
        id
      );
      toast.success(result?.notice);
      getSyndicateDetail();
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleAddLeadCarry = async () => {
    try {
      const result = await syndicateManagementManager.addSyndicateLeadCarry(
        selectedSyndicate?.value,
        selectedLead?.value,
        leadCarry
      );
      toast.success(result?.notice);
      getSyndicateDetail();
    } catch (e: any) {
      toast.error(e);
    }
  };
  useEffect(() => {
    if (selectedSyndicate?.value) getSyndicateDetail();
  }, [selectedSyndicate?.value]);

  const reset = () => {
    setName("");
    setIsPvtSynd(false);
    setIsTopSynd(false);
    setSyndicateHeader("");
    setSyndicateAbout("");
    setInvestmentThesis("");
    setMinInvestment(0);
    setMaxInvestment(0);
    setDealPerYear(0);
    setProfileImage([]);
    setCoverImage([]);
    setMaxCorpus(0);
    setPreCommitTenure(0);
    setManagementFee(0);
    setDrawdownNo(0);
    setDrawdownInterval(0);
    setLvCarry(0);
    setHurdleRate(0);
  };

  const updateSyndicate = async () => {
    try {
      const result = await syndicateManagementManager.updateSyndicate(
        name,
        isPvtSynd,
        syndicateType,
        syndicateHeader,
        syndicateAbout,
        investmentThesis,
        mininvestment,
        maxInvestment,
        dealPerYear,
        coverImage[0] ?? [],
        profileImage[0] ?? [],
        maxCorpus,
        preCommitTenure,
        managementFee,
        drawdownNo,
        drawdownInterval,
        lvCarry,
        hurdleRate,
        selectedMailer?.value ?? ""
      );
      if (result) {
        setUpdating(false);
        toast.success(result?.details);
        reset();
      }
    } catch (e: any) {
      toast.error(e);
      setUpdating(false);
    }
  };

  const isDisable = () => {
    return syndicateType?.value === "zeno"
      ? !maxCorpus ||
          !preCommitTenure ||
          !managementFee ||
          !drawdownNo ||
          !drawdownInterval ||
          !lvCarry ||
          !hurdleRate
      : false;
  };

  return (
    <Grid container spacing={2} rowGap={2}>
      <Grid item xs={5}>
        <Box display="flex" alignItems="center">
          <Typography>Select Syndicate &nbsp; </Typography>
          <Tooltip title="Only the investors who are active, published, joined, and not part of any private syndicate can be selected">
            <Help fontSize="small" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <Box width="100%">
          <LvAutoComplete
            label={"Select Syndicate"}
            options={syndicateOptions}
            setOptions={setSyndicateOptions}
            selectedObject={selectedSyndicate}
            setSelectedObject={setSelectedSyndicate}
            onSelect={() => setSyndicateOptions([])}
            onChange={searchSyndicate}
          />
        </Box>
      </Grid>
      <Grid item xs={3} />

      {loading && (
        <Grid item xs={12}>
          <LinearProgress />{" "}
        </Grid>
      )}
      {selectedSyndicate?.value && !loading ? (
        <>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Select Name &nbsp; </Typography>
              <Tooltip title="Please enter a unique name">
                <Help fontSize="small" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <Box width="100%">
                <CustomTextField
                  name="name"
                  value={name}
                  onChange={(val: string) => {
                    setName(val);
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} />

          {syndicateType === "normal" ? (
            <>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography> Syndicate Header &nbsp; </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="name"
                    value={syndicateHeader}
                    onChange={(val: string) => {
                      setSyndicateHeader(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography> Syndicate About &nbsp; </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="syndicateAbout"
                    value={syndicateAbout}
                    onChange={(val: string) => {
                      setSyndicateAbout(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Syndicate Investment Thesis &nbsp; </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="investmentThesis"
                    value={investmentThesis}
                    onChange={(val: string) => {
                      setInvestmentThesis(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>
                    {" "}
                    Syndicate Minimum Typical Investment (in Lakhs) &nbsp;{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    value={mininvestment}
                    type="number"
                    onChange={(val: number) => {
                      setMinInvestment(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>
                    Syndicate Maximum Typical Investment (in Lakhs)&nbsp;{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    type="number"
                    value={maxInvestment}
                    onChange={(val: number) => {
                      setMaxInvestment(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Syndicate Deal Per Year &nbsp; </Typography>
                  <Tooltip title="How many deals the syndicate is planning to bring in an year?">
                    <Help fontSize="small" />
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    type="number"
                    value={dealPerYear}
                    onChange={(val: number) => {
                      setDealPerYear(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Syndicate Profile Image &nbsp; </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <FileUpload
                  customStyles={{ maxWidth: 360 }}
                  placeholder="select a file"
                  helperText="upload your file here"
                  selectedFile={(file: any) => setProfileImage(file)}
                  multiple
                  value={profileImage}
                  accept={{
                    "image/svg": [".png", ".svg"],
                    "text/pdf": [".pdf"],
                  }}
                  title="File Upload Dialog Box"
                  hideDocumentVault
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Syndicate Cover Image &nbsp; </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <FileUpload
                  customStyles={{ maxWidth: 360 }}
                  placeholder="select a file"
                  helperText="upload your file here"
                  selectedFile={(file: any) => setCoverImage(file)}
                  multiple
                  value={coverImage}
                  accept={{
                    "image/svg": [".png", ".svg"],
                    "text/pdf": [".pdf"],
                  }}
                  title="File Upload Dialog Box"
                  hideDocumentVault
                />
              </Grid>
              <Grid item xs={3} />{" "}
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography> Max. Corpus (Lakhs) </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="maxCorpus"
                    value={maxCorpus}
                    type="number"
                    onChange={(val: number) => {
                      setMaxCorpus(val);
                    }}
                  />{" "}
                </Box>
                <Box ml={2}> INR</Box>
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>PreCommit Syndicate Tenure</Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="PreCommit Syndicate Tenure"
                    value={preCommitTenure}
                    type="number"
                    onChange={(val: number) => {
                      setPreCommitTenure(val);
                    }}
                  />{" "}
                </Box>
                <Box ml={2}>Years</Box>
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Management Fee (per year)</Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="Management Fee (per year)"
                    value={managementFee}
                    type="number"
                    onChange={(val: number) => {
                      setManagementFee(val);
                    }}
                  />{" "}
                </Box>
                <Box ml={2}>%</Box>
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Number of Drawdowns</Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="Number of Drawdowns"
                    value={drawdownNo}
                    type="number"
                    onChange={(val: number) => {
                      setDrawdownNo(val);
                    }}
                  />{" "}
                </Box>
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Expected Drawdown Interval</Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="Expected Drawdown Interval"
                    value={drawdownInterval}
                    type="number"
                    onChange={(val: number) => {
                      setDrawdownInterval(val);
                    }}
                  />{" "}
                </Box>
                <Box ml={2}>Months</Box>
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>LV Carry</Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="LV Carry"
                    value={lvCarry}
                    type="number"
                    onChange={(val: number) => {
                      setLvCarry(val);
                    }}
                  />{" "}
                </Box>
                <Box ml={2}>%</Box>
              </Grid>
              <Grid item xs={3} />

              <Grid item xs={5}>
                <Box display="flex" alignItems="center">
                  <Typography>Hurdle Rate</Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box width="100%">
                  <CustomTextField
                    name="Hurdle Rate"
                    value={hurdleRate}
                    type="number"
                    onChange={(val: number) => {
                      setHurdleRate(val);
                    }}
                  />{" "}
                </Box>
                <Box ml={2}>%</Box>
              </Grid>
              <Grid item xs={3} />
            </>
          )}

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Is Private Syndicate ? &nbsp; </Typography>
              <Tooltip title="Check this box if it is a private syndicate">
                <Help fontSize="small" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPvtSynd}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsPvtSynd(e.target.checked)
                  }
                />
              }
              label=""
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Is Top Syndicate ? &nbsp; </Typography>
              <Tooltip title="Check this box if it is a private syndicate">
                <Help fontSize="small" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTopSynd}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsTopSynd(e.target.checked)
                  }
                />
              }
              label=""
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>
                Add Specific Backers to Private Syndicate &nbsp;{" "}
              </Typography>
              <Tooltip title="This is non-mandatory">
                <Help fontSize="small" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <LvAutoComplete
              label={"Select Mailer List"}
              options={mailerOptions}
              setOptions={setMailerOptions}
              selectedObject={selectedMailer}
              setSelectedObject={setSelectedMailer}
              onSelect={() => setMailerOptions([])}
              onChange={getMailersOptions}
            />
          </Grid>
          <Grid item xs={3} />

          {syndicateType === "zeno" && (
            <Grid item xs={9} container>
              <Paper sx={{ width: "100%", padding: 2 }}>
                <TeComplexTable
                  customNoResultsOverlayHeight={30}
                  rows={leadCarryTableData}
                  columns={[
                    {
                      field: "investor_name",
                      headerName: "Investor Name",
                      minWidth: 500,
                    },
                    { field: "carry", headerName: "Carry", minWidth: 100 },
                    {
                      field: "action",
                      headerName: "Action",
                      minWidth: 200,
                      renderCell: (params: any) => (
                        <ActionButton
                          onClick={() =>
                            handleDeleteLeadCarry(params?.row?.investor_id)
                          }
                        >
                          Delete
                        </ActionButton>
                      ),
                    },
                  ]}
                  rowHeight={40}
                  headerHeight={50}
                  minWidth={750}
                  enableAdvancedFiltering
                  isUnFilteredDataPresent={true}
                  pageSize={5}
                  paginationMode="server"
                />
                <br />
                <Grid container gap={2}>
                  <Grid item xs={4}>
                    Select Syndicate Lead
                  </Grid>
                  <Grid item xs={6}>
                    <LvAutoComplete
                      label={"Select Syndicate Lead"}
                      options={leadOptions}
                      setOptions={setLeadOptions}
                      selectedObject={selectedLead}
                      setSelectedObject={setSelectedLead}
                      onSelect={() => setLeadOptions([])}
                      onChange={getLeadOptions}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    Carry(%)
                  </Grid>
                  <Grid item xs={6} display="flex" columnGap={2}>
                    <Box width="100%">
                      <CustomTextField
                        label="Add Lead Carry(%)"
                        value={leadCarry}
                        onChange={(val: number) => setLeadCarry(val)}
                      />
                    </Box>
                    <ActionButton
                      onClick={handleAddLeadCarry}
                      disabled={!selectedLead?.value}
                    >
                      Add
                    </ActionButton>
                  </Grid>

                  <Grid item xs={4}>
                    Total Carry(%)
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      value={
                        lvCarry +
                        leadCarryTableData.reduce(
                          (n, { carry }) => n + carry,
                          0
                        )
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          <Grid item xs={9} display="flex">
            <Box ml="auto">
              <ActionButton
                onClick={updateSyndicate}
                isLoading={updating}
                disabled={
                  updating ||
                  !selectedSyndicate?.value ||
                  !name.trim() ||
                  isDisable()
                }
              >
                Update Syndicate
              </ActionButton>
            </Box>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default UpdateSyndicate;
