import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/v1";

export const getCaDocsByStatus = (status: string, page: number) => {
	const finalURL =
		API_BASE_URL + `/leegality/?ca_doc_status=${status}&page=${page}`;
	return Axios.get(finalURL);
};

export const getInvestorPayments = (paymentStatus: string, page: number) => {
	const finalURL =
		API_BASE_URL +
		`/investor_payments/?order_type=aif&page=${page}&payment_status=${paymentStatus}`;
	return Axios.get(finalURL);
};
