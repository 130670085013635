import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/v1/admin";
const API_BASE_URL2 = "https://api.staging.letsventure.com/v1";
const BACK_STAGE_URL = "https://backstage.letsventure.com";
const config = {
  headers: { "content-type": "multipart/form-data" }
};
export const getSyndicateDealDocuments = (page: number) => {
  const finalURL = API_BASE_URL + `/syndicate_management/ps_manager/?page=${page}`;
  return Axios.get(finalURL);
};

export const createSyndicate = (data: any) => {
  const finalURL = API_BASE_URL2 + `/syndicates/create_or_update_syndicate/?data_source=lv_admin`;
  return Axios.post(finalURL, data, config);
};

export const updateSyndicate = (data: any) => {
  const finalURL = API_BASE_URL2 + `/syndicates/create_or_update_syndicate/?data_source=lv_admin`;
  return Axios.put(finalURL, data, config);
};

export const getSyndicateDetail = (id: string) => {
  const finalURL = API_BASE_URL2 + `/syndicates/get_admin_syndicate_details/?id=${id}`;
  return Axios.get(finalURL);
};

export const deleteSyndicateLeadCarry = (syndicate_id: string, investor_id: string) => {
  const finalURL =
    API_BASE_URL2 +
    `/syndicates/delete_lead_carry_for_syndicate/?syndicate_id=${syndicate_id}&investor_id=${investor_id}`;
  return Axios.get(finalURL);
};

export const addSyndicateLeadCarry = (data: any) => {
  const finalURL = API_BASE_URL2 + `/syndicates/add_lead_carry_for_syndicate/`;
  return Axios.post(finalURL, data);
};

export const createStartup = (data: any) => {
  const finalURL = API_BASE_URL2 + `/syndicates/create_or_update_startup/?data_source=lv_admin&type_of_action=create`;
  return Axios.post(finalURL, data, config);
};

export const updateStartup = () => {
  const finalURL = API_BASE_URL2 + `/syndicates/create_or_update_startup/?data_source=lv_admin&type_of_action=create`;
  return Axios.put(finalURL, {});
};

