export const SortByOptions = [
  { label: "Ascending Date", value: "asc" },
  { label: "Descending Date", value: "dsc" },
];

export const InitialScreeningTabs: any = [
  {
    name: "Awaiting",
    value: "awaiting",
  },
  {
    name: "Rejected",
    value: "rejected",
  },
];
