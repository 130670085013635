import {
  getInitialScreeningDocuments,
  getStartupDetail,
  startupActions,
  initialScreeningAction,
  finalScreeningAction,
  initialScreeningMarkAsGood,
  getFinalScreeningDocuments,
  getStartupReports,
  parseReport,
  reportAction,
  getCommitmentInvestorCommunication,
  searchDealName,
} from "../services/startupServices";
import { getFormattedDate } from "../../../utils/dateUtils";

type awaitingDocs = {
  serial_no: number;
  startup_name: string;
  startup_sector: string;
  applied_on: string;
  mark_as_good: any;
  referred_type_and_by: string;
  action: any;
};

type rejectedDocs = {
  serial_no: number;
  startup_name: string;
  applied_on: string;
  marked_good: any;
  referred_type_and_by: string;
  rejected_by: string;
  action: any;
};

export default class StartupManagement {
  private userData: any;

  constructor(AuthContext: any) {
    this.userData = AuthContext.authState;
  }
  private getReferredTypeAndBy(by: string, type: string) {
    return `${by ? `${by}` : ""} - ${type ? `${type}` : ""}`;
  }

  private getFormattedAwaitingDocs = (data: any[], page: number) => {
    const startId = page === 1 ? 1 : (page - 1) * 10 + 1;
    let result: awaitingDocs[] = [];
    data.forEach((item: any, idx: number) => {
      result.push({
        ...item,
        serial_no: startId + idx,
        startup_name: item?.name ?? "",
        startup_sector: item?.sector ?? "",
        applied_on: getFormattedDate(item?.applied_on) ?? "",
        referred_type_and_by: this.getReferredTypeAndBy(
          item?.referred_by,
          item?.referrer_type
        ),
        mark_as_good: item?.mark_good ?? false,
        action: "",
      });
    });
    return result;
  };

  private getFormattedRejectedDocs = (data: any[], page: number) => {
    const startId = page === 1 ? 1 : (page - 1) * 10 + 1;
    let result: rejectedDocs[] = [];
    data.forEach((item: any, idx: number) => {
      result.push({
        ...item,
        serial_no: startId + idx,
        startup_name: item?.name ?? "",
        applied_on: getFormattedDate(item?.applied_on) ?? "",
        referred_type_and_by: this.getReferredTypeAndBy(
          item?.referred_by,
          item?.referrer_type
        ),
        rejected_by: item?.action_taken_by ?? "",
        marked_good: item?.mark_good === 1 ?? false,
        action: "",
      });
    });
    return result;
  };

  private getFormattedFinalScreeningDocs = (data: any[], page: number) => {
    const startId = page === 1 ? 1 : (page - 1) * 10 + 1;
    let result: any[] = [];
    data.forEach((item: any, idx: number) => {
      result.push({
        ...item,
        serial_no: startId + idx,
        startup_name: item?.name ?? "",
        startup_sector: item?.sector ?? "",
        approved_from_initial: getFormattedDate(item?.action_taken_on) ?? "",
        approved_from_initial_by: item?.action_taken_by ?? "",
        action: "",
      });
    });
    return result;
  };

  async getInitialScreeningDocs(
    status: string,
    page: number,
    name: string,
    order: string,
    sector_id: string
  ) {
    try {
      let result = await getInitialScreeningDocuments(
        status,
        page,
        name,
        order,
        sector_id
      );
      let formattedData =
        status === "awaiting"
          ? this.getFormattedAwaitingDocs(result?.data?.results, page)
          : this.getFormattedRejectedDocs(result?.data?.results, page);
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getFinalScreeningDocs(
    status: string,
    page: number,
    sortBy: string,
    sortByDoc: string,
    dealType: string,
    sectorId: string,
    sectorPoc: string,
    name: string
  ) {
    try {
      let result = await getFinalScreeningDocuments(
        status,
        page,
        sortBy,
        sortByDoc,
        dealType,
        sectorId,
        sectorPoc,
        name
      );
      let formattedData = this.getFormattedFinalScreeningDocs(
        result?.data?.results,
        page
      );

      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getStartupDetail(start_id: string) {
    try {
      let result = await getStartupDetail(start_id);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async startupActions(start_id: string, action: string, data: any = {}) {
    try {
      const formData = new FormData();
      if (data?.document_url) {
        formData.append("document_url", data?.document_url);
      }
      let result = await startupActions(start_id, action, formData);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async initialScreeningAction(startup_id: string, accept: boolean | null) {
    try {
      let data = {
        startup_id,
        accept: accept,
      };
      let result = await initialScreeningAction(data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async finalScreeningAction(startup_id: string, accept: boolean) {
    try {
      let data = {
        startup_id,
        accept,
      };
      let result = await finalScreeningAction(data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }
  async initialScreeningMarkAsGood(startup_id: string, flag: any) {
    try {
      let data = {
        startup_id,
        mark_good: flag,
      };
      let result = await initialScreeningMarkAsGood(data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async getStartupReports(startupId: string, page: number) {
    try {
      let result = await getStartupReports(startupId, page);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async parseReport() {
    try {
      let result = await parseReport();

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async publishReport(startupId: string) {
    try {
      let data = {
        publish: true,
      };
      let result = await reportAction(startupId, data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async archiveReport(startupId: string) {
    try {
      let data = {
        archive: true,
      };
      let result = await reportAction(startupId, data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }



private getFormattedCommitmentInvestorCommunication = (data: any[], page: number) => {
  let result: any[] = [];
  data.forEach((item: any, idx: number) => {
    result.push({
      ...item,
      startup_name: item?.startup_name??"",
      update_type: item?.update_type ?? "",
      email_subject: item?.email_subject ?? "",
      email_content: item?.email_content??"",
      attachements: item?.attachements ?? "",
      submitted_date: getFormattedDate(item?.submitted_date) ?? "",
      action:item?.action ?? "unpublish"
    });
  });
  return result;
};

async getCommitmentInvestorCommunication(
  page: number,
) {
  try {
    // let result = await getCommitmentInvestorCommunication(
    //   page,
    // );


  let result = {
        "count": 2,
        "next": "https://api.staging.letsventure.com/v1/admin/user_management/new_verify_investor/?mode=awaiting&page=2&search_text=",
        "previous": null,
        "results": [
          {
            "id": 57,
            "startup_name": "Goldy 40",
            "update_type": "Future Fundraise/Preemptive/Rights Issue",
            "email_subject": "Testing Email Subject",
            "email_content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ​https://www.letsventure.com/",
            "attachements": ["dummy.pdf","jpeg-home.jpg", "jpegsystems-home.jpg"],
            "submitted_date": "2024-04-12",
            "action":"publish"
          },
          {
            "id": 32,
            "startup_name": "Nykaa",
            "update_type": "Conversion",
            "email_subject": "Nykaa Specific Subject Sample",
            "email_content": `<div><p><b>Startup Portfolio Updates</b></p><p><i>Portfolio Team Use this feature to publish startup reports and update to investors</i></p><p><a href="https://www.letsventure.com/">LetsVenture</a></p></div>https://www.letsventure.com/`,
            "attachements": ["jpegsystems-home.jpg"],
            "submitted_date": "2024-04-12",
            "action":"unpublish"
          },
        ]
      }
     
    let formattedData = this.getFormattedCommitmentInvestorCommunication(
      result?.results,
      page
    );

    return { formattedData, count: result?.count };
  } catch (error) {
    throw error;
  }
}

async searchDealName(search: string) {
  try {
    const res = await searchDealName(search);
    return { data: res.data.results };
  } catch (error) {
    throw error;
  }
}

}