import { ThemeProvider, createTheme } from "@mui/material";
import React, { FC, ReactElement, ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  typography: {
    fontFamily: "Work Sans !important",
  },
});

type Props = {
  children: ReactNode;
};
const ThemeContext: FC<Props> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      {props?.children}
    </ThemeProvider>
  );
};

export default ThemeContext;
