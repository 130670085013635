import { Box, Grid, Stack } from "@mui/material";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import React from "react";
import { breadCrumbData } from "../../constants/mailCentralConstant/MailCentralConstant";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { useNavigate } from "react-router-dom";

const MailCentral = () => {
  const navigate = useNavigate();
  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>
      <br />
      <Stack direction="row" gap="1rem">
        <ActionButton onClick={() => navigate("/campaign/mailer-lists")}>
          Lists
        </ActionButton>
        <ActionButton onClick={() => navigate("/campaign/campaigns-list")}>
          Campaign Manager
        </ActionButton>
        <ActionButton onClick={() => navigate("/campaign/mailer-analytics")}>
          Analytics
        </ActionButton>
      </Stack>
    </Box>
  );
};

export default MailCentral;
