export const MUI_X_PRO_LICENSE_KEY =
  "cef8c03fe7f22fac91039cafa84c2f14T1JERVI6NDMwNzgsRVhQSVJZPTE2ODMyNzcwNzAwMDAsS0VZVkVSU0lPTj0x";

export const httpMethods = {
  HTTP_GET: "get",
  HTTP_POST: "post",
  HTTP_PATCH: "patch",
  HTTP_PUT: "put",
  HTTP_DELETE: "delete",
};

export const API_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const CsvFilename = {
  DRAWDOWN: "Drawdown-payments-data",
  TRANSACTION_FEE: "Transaction-fee-payments-data",
  AIF: "AIF-payments-data",
  STARTUP_DOCUMENT: "Startup-document-list",
  DEFAULT: "Payments-data",
};
