import { Box, Grid, Stack } from "@mui/material";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { breadCrumbDataMailerLists } from "../../../constants/mailCentralConstant/MailCentralConstant";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { API_STATUS } from "../../../../../constants/commonConstants";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { isEmpty } from "lodash";

const MailerLists = () => {
  const navigate = useNavigate();
  const navigationFunction = () => navigate(-1);
  const navigateToCreate = () => navigate("/campaign/create-mailer-list");

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const column = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 180,
    },
    {
      field: "recipients",
      headerName: "Recipients",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 3,
      minWidth: 150,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Stack direction="row" gap={2}>
            <OutlinedButton
              onClick={() =>
                navigate(`/campaign/edit-mailer-list/${params?.row?.id}`)
              }
              customStyles={{
                color: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),
              }}
            >
              Edit
            </OutlinedButton>
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 500),
                borderColor: getColor("action_button", 500),
              }}
            >
              Delete
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    setFormattedData([{ id: 1, name: "Asish", recipients: 6 }]);
  }, []);

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbDataMailerLists?.steps}
            pageHeader={{
              ...breadCrumbDataMailerLists?.pageHeader,
              navigationFunction,
            }}
            stepKey={breadCrumbDataMailerLists?.stepKey}
            breadcrumbSeparator={breadCrumbDataMailerLists?.breadcrumbSeparator}
          />
        </Grid>
        <Grid item xs={6}>
          <ActionButton
            onClick={navigateToCreate}
            badgeStyles={{
              display: "block",
              width: "fit-content",
              marginLeft: "auto",
            }}
          >
            Create New List
          </ActionButton>
        </Grid>
      </Grid>

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <TeComplexTable
            rows={formattedData}
            columns={column}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
    </Box>
  );
};

export default MailerLists;
