export enum AdminActionTypes {
  DEACTIVATE = "deactivate",
  ACTIVATE = "activate",
  UNPUBLISH = "unpublish",
  PUBLISH = "publish",
  MAKE_PERMANENT = "makepermanent",
  MAKE_PAID = "makepaid",
  MAKE_UNPAID = "makeunpaid",
  MAKE_NON_FAAS = "makenonfaas",
  MAKE_FAAS = "makefaas",
  BLOCK_DEAL_ACCESS = "blockdealaccess",
  UNBLOCK_DEAL_ACCESS = "unblockdealaccess",
  UPDATE_ALLOWED_SECTOR_LIST = "update_allowed_sector_list",
  ADD_HASHTAG = "addhashtag",
  MAKE_INVESTOR = "makeinvestor",
  ADD_COMMIT = "add_commit",
  MODIFY_COMMIT = "modify_commit",
  WITHDRAW_COMMIT = "withdraw_commit",
  ADD_BACKER_TO_PRIVATE_SYNDICATE = "add_backer_to_private_syndicate",
  REMOVE_BACKER_FROM_PRIVATE_SYNDICATE = "remove_backer_from_private_syndicate",
  GIVE_PLATFORM_ACCESS = "give_platform_access",
  REVOKE_PLATFORM_ACCESS = "revoke_platform_access",
  UPDATE_CA_ONBOARDING_INFO = "update_ca_onboarding_info",
  CHANGE_EMAIL = "change_email",
  CHANGE_CALENDLY_LINK = "change_calendly_link",
  UPDATE_COMMISSION = "update_commission",
  PARTNER_PAYMENT_STATUS = "partner_payment_status",
  ADD_INDIVIDUAL = "add_individual",
  ADD_VIA_LIST = "add_via_list",
}
