import { AuthContextProps, UserAuthInfo } from "../../../contexts/authContext";

import {
  getSyndicateDealDocuments,
  createSyndicate,
  updateSyndicate,
  createStartup,
  getSyndicateDetail,
  deleteSyndicateLeadCarry,
  addSyndicateLeadCarry
} from "../services/syndicateManagementService";

export default class SyndicateManagement {
  private userData: UserAuthInfo;

  constructor(AuthContext: AuthContextProps) {
    this.userData = AuthContext.authState;
  }

  async getSyndicateDealDocuments(page: number) {
    try {
      let result = await getSyndicateDealDocuments(page);
      const formattedData = result?.data?.results?.map((item: any, index: number) => ({
        ...item,
        sno: index + 1,
        dpiit_file: item?.dpiit_file ? "Yes" : "No"
      }));
      return { result: formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async createSyndicate(
    syndicate_name: string,
    investor_id: string,
    syndicate_type: string,
    is_private: boolean,
    header: string,
    about: string,
    investment_thesis: string,
    min_typical_investment: number,
    max_typical_investment: number,
    deal_per_year: number,
    syndicate_cover_image: any,
    syndicate_profile_img: any,
    syndicate_corpus: number,
    pre_commit_tenure: number,
    management_fee: number,
    no_of_drawdowns: number,
    drawdown_interval: number,
    lv_carry: number,
    hurdle_rate: number,
    mailer_list_id: string
  ) {
    try {
      const formData = new FormData();
      formData.append("syndicate_name", syndicate_name);
      formData.append("investor_user_id", investor_id);
      formData.append("syndicate_type", syndicate_type);
      formData.append("is_private", is_private?.toString());
      formData.append("mailer_list_id", mailer_list_id);
      if (syndicate_type === "zeno") {
        formData.append("syndicate_corpus", syndicate_corpus.toString());
        formData.append("pre_commit_tenure", pre_commit_tenure.toString());
        formData.append("management_fee", management_fee.toString());
        formData.append("no_of_drawdowns", no_of_drawdowns.toString());
        formData.append("drawdown_interval", drawdown_interval.toString());
        formData.append("lv_carry", lv_carry.toString());
        formData.append("hurdle_rate", hurdle_rate.toString());
      }
      if (syndicate_type === "normal") {
        formData.append("header", header);
        formData.append("description", about);
        formData.append("investment_thesis", investment_thesis);
        formData.append("min_typical_investment", min_typical_investment.toString());
        formData.append("max_typical_investment", max_typical_investment.toString());
        formData.append("deal_per_year", deal_per_year.toString());
        formData.append("syndicate_cover_image", syndicate_cover_image);
        formData.append("syndicate_profile_img", syndicate_profile_img);
      }

      let result = await createSyndicate(formData);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateSyndicate(
    syndicate_name: string,
    is_private: boolean,
    syndicate_type: string,
    header: string,
    about: string,
    investment_thesis: string,
    min_typical_investment: any,
    max_typical_investment: any,
    deal_per_year: any,
    syndicate_cover_image: any,
    syndicate_profile_img: any,
    syndicate_corpus: number,
    pre_commit_tenure: number,
    management_fee: number,
    no_of_drawdowns: number,
    drawdown_interval: number,
    lv_carry: number,
    hurdle_rate: number,
    mailer_list_id: string
  ) {
    try {
      const formData = new FormData();
      formData.append("syndicate_name", syndicate_name);
      formData.append("is_private", is_private?.toString());
      formData.append("mailer_list_id", mailer_list_id);

      if (syndicate_type === "normal") {
        formData.append("header", header);
        formData.append("description", about);
        formData.append("investment_thesis", investment_thesis);
        formData.append("min_typical_investment", min_typical_investment.toString());
        formData.append("max_typical_investment", max_typical_investment.toString());
        formData.append("deal_per_year", deal_per_year.toString());
        formData.append("syndicate_cover_image", syndicate_cover_image);
        formData.append("syndicate_profile_img", syndicate_profile_img);
      }

      if (syndicate_type === "zeno") {
        formData.append("syndicate_corpus", syndicate_corpus.toString());
        formData.append("pre_commit_tenure", pre_commit_tenure.toString());
        formData.append("management_fee", management_fee.toString());
        formData.append("no_of_drawdowns", no_of_drawdowns.toString());
        formData.append("drawdown_interval", drawdown_interval.toString());
        formData.append("lv_carry", lv_carry.toString());
        formData.append("hurdle_rate", hurdle_rate.toString());
      }

      let result = await updateSyndicate(formData);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async getSyndicateDetail(id: string) {
    try {
      let result = await getSyndicateDetail(id);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSyndicateLeadCarry(syndicate_id: string, investor_id: string) {
    try {
      let result = await deleteSyndicateLeadCarry(syndicate_id, investor_id);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async addSyndicateLeadCarry(selected_syn_id: string, lead_user_id: string, lead_carry: number) {
    try {
      const data = {
        selected_syn_id,
        lead_user_id,
        lead_carry
      };
      let result = await addSyndicateLeadCarry(data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async createStartup(
    startup_name: string,
    startup_stage: string,
    startup_location_tokens: string,
    permalink: string,
    startup_business_type: string,
    summary: string,
    startup_pitch_deck: string,
    funding_amount: number,
    twitter_pitch: string
  ) {
    try {
      const formData = new FormData();

      formData.append("startup_name", startup_name);
      formData.append("permalink", permalink);
      formData.append("product_summary", summary);
      formData.append("startup_business_type", startup_business_type);
      formData.append("startup_stage", startup_stage);
      formData.append("startup_pitch_deck", startup_pitch_deck);
      formData.append("funding_amount", funding_amount?.toString());
      formData.append("startup_location_tokens", startup_location_tokens);
      formData.append("summary", twitter_pitch);

      let result = await createStartup(formData);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}

