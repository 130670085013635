import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { HorizontalTabs } from "@trica-equity/ui-library.tabs.horizontal-tabs";
import { useState } from "react";
import {
  RsvpTabs,
  breadCrumbData,
} from "../../constants/rsvpConstants/RsvpConstant";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "@trica-equity/ui-library.placeholders.table-placeholder";
import { TeComplexTable } from "@trica-equity/ui-library.tables.te-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { getColor } from "@trica-equity/ui-library.styles.colors";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import { Stack } from "@mui/material";
import { Breadcrumb } from "@trica-equity/ui-library.navs.breadcrumb";

export default function Rsvp() {
  const [selectedTab, setSelectedTab] = useState(RsvpTabs[0].value);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const column = [
    {
      field: "title",
      headerName: "Event Title",
      flex: 2,
      minWidth: 180,
    },
    {
      field: "type",
      headerName: "Event Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "date_time",
      headerName: "Event Date & Time",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 3,
      minWidth: 150,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Stack>
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),
              }}
            >
              Edit
            </OutlinedButton>
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),
              }}
            >
              Delete
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>

        <Grid item xs={8} justifyContent="end" display="flex">
          <ActionButton>Create Event</ActionButton>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={RsvpTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      <br />
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <TeComplexTable
            rows={formattedData}
            columns={column}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
    </Box>
  );
}
