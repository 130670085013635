import { Help } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import React, { useEffect, useState } from "react";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { FileUpload } from "@trica-equity/ui-library.form-elements.file-upload";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import SyndicateManagement from "../../managers/syndicateManagementManager";
import { API_STATUS } from "../../../../constants/commonConstants";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

export const SyndicateTypeOptions = [
  {
    label: "Regular",
    value: "normal",
  },
  {
    label: "Zeno",
    value: "zeno",
  },
];

const CreateSyndicate = () => {
  const authUser = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authUser);
  const syndicateManagementManager = new SyndicateManagement(authUser);
  const [creating, setCreating] = useState(API_STATUS.SUCCESS);
  const [name, setName] = useState("");

  const [selectedInvestor, setSelectedInvestor] = useState<any>({});
  const [investorOptions, setInvestorOptions] = useState<any>([]);
  const handleInvestorSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setInvestorOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const [syndicateType, setSyndicateType] = useState(SyndicateTypeOptions[0]);
  const [isPvtSynd, setIsPvtSynd] = useState(false);

  const [selectedMailer, setSelectedMailer] = useState<any>({});
  const [mailerOptions, setMailerOptions] = useState<any>([]);
  const getMailersOptions = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData(
        search,
        "mailer_list"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setMailerOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const [syndicateHeader, setSyndicateHeader] = useState("");
  const [syndicateAbout, setSyndicateAbout] = useState("");
  const [investmentThesis, setInvestmentThesis] = useState("");
  const [mininvestment, setMinInvestment] = useState(0);
  const [maxInvestment, setMaxInvestment] = useState(0);
  const [dealPerYear, setDealPerYear] = useState(0);
  const [profileImage, setProfileImage] = useState([]);
  const [coverImage, setCoverImage] = useState([]);

  const [maxCorpus, setMaxCorpus] = useState(0);
  const [preCommitTenure, setPreCommitTenure] = useState(0);
  const [managementFee, setManagementFee] = useState(0);
  const [drawdownNo, setDrawdownNo] = useState(0);
  const [drawdownInterval, setDrawdownInterval] = useState(0);
  const [lvCarry, setLvCarry] = useState(0);
  const [hurdleRate, setHurdleRate] = useState(0);

  const reset = () => {
    setName("");
    setSelectedInvestor({});
    setInvestorOptions([]);
    setSyndicateType(SyndicateTypeOptions[0]);
    setIsPvtSynd(false);
    setSelectedMailer({});
    setMailerOptions([]);
    setSyndicateHeader("");
    setSyndicateAbout("");
    setInvestmentThesis("");
    setMinInvestment(0);
    setMaxInvestment(0);
    setDealPerYear(0);
    setProfileImage([]);
    setCoverImage([]);
    setMaxCorpus(0);
    setPreCommitTenure(0);
    setManagementFee(0);
    setDrawdownNo(0);
    setDrawdownInterval(0);
    setLvCarry(0);
    setHurdleRate(0);
  };

  const createSyndicate = async () => {
    try {
      setCreating(API_STATUS.LOADING);
      const result = await syndicateManagementManager.createSyndicate(
        name,
        selectedInvestor?.value ?? "",
        syndicateType?.value ?? "",
        isPvtSynd,
        syndicateHeader,
        syndicateAbout,
        investmentThesis,
        mininvestment,
        maxInvestment,
        dealPerYear,
        coverImage[0],
        profileImage[0],
        maxCorpus,
        preCommitTenure,
        managementFee,
        drawdownNo,
        drawdownInterval,
        lvCarry,
        hurdleRate,
        selectedMailer?.value ?? ""
      );
      if (result) {
        setCreating(API_STATUS.SUCCESS);
        toast.success(result?.details);
        reset();
      }
    } catch (e: any) {
      setCreating(API_STATUS.ERROR);
      toast.error(e);
    }
  };

  const isDisable = () => {
    return syndicateType?.value === "zeno"
      ? !maxCorpus ||
          !preCommitTenure ||
          !managementFee ||
          !drawdownNo ||
          !drawdownInterval ||
          !lvCarry ||
          !hurdleRate
      : false;
  };

  return (
    <Grid container spacing={2} rowGap={2}>
      <Grid item xs={5}>
        <Box display="flex" alignItems="center">
          <Typography> Syndicate Name &nbsp; </Typography>
          <Tooltip title="Please enter a unique name">
            <Help fontSize="small" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <Box width="100%">
          <CustomTextField
            name="name"
            value={name}
            onChange={(val: string) => {
              setName(val);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={5}>
        <Box display="flex" alignItems="center">
          <Typography>Select Investor &nbsp; </Typography>
          <Tooltip title="Only the investors who are active, published, joined, and not part of any private syndicate can be selected">
            <Help fontSize="small" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <LvAutoComplete
          label={"Select Investor User"}
          options={investorOptions}
          setOptions={setInvestorOptions}
          selectedObject={selectedInvestor}
          setSelectedObject={setSelectedInvestor}
          onSelect={() => setInvestorOptions([])}
          onChange={handleInvestorSearch}
        />
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={5}>
        <Box display="flex" alignItems="center">
          <Typography>Select syndicate type &nbsp; </Typography>
          <Tooltip title="Select 'Regular' for Private syndicate">
            <Help fontSize="small" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <Box width={"100%"}>
          <CoreDropdown
            label="Syndicate Type"
            options={SyndicateTypeOptions}
            value={syndicateType}
            onClickMenuItem={(value: any) => setSyndicateType(value)}
          />
        </Box>
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={5}>
        <Box display="flex" alignItems="center">
          <Typography>Is Private Syndicate ? &nbsp; </Typography>
          <Tooltip title="Check this box if it is a private syndicate">
            <Help fontSize="small" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={isPvtSynd}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setIsPvtSynd(e.target.checked)
              }
            />
          }
          label=""
        />
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={5}>
        <Box display="flex" alignItems="center">
          <Typography>
            Add Specific Backers to Private Syndicate &nbsp;{" "}
          </Typography>
          <Tooltip title="This is non-mandatory">
            <Help fontSize="small" />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <LvAutoComplete
          label={"Select Mailer List"}
          options={mailerOptions}
          setOptions={setMailerOptions}
          selectedObject={selectedMailer}
          setSelectedObject={setSelectedMailer}
          onSelect={() => setMailerOptions([])}
          onChange={getMailersOptions}
        />
      </Grid>
      <Grid item xs={3} />

      {syndicateType?.value === "normal" ? (
        <>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography> Syndicate Header &nbsp; </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="name"
                value={syndicateHeader}
                onChange={(val: string) => {
                  setSyndicateHeader(val);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography> Syndicate About &nbsp; </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="name"
                value={syndicateAbout}
                onChange={(val: string) => {
                  setSyndicateAbout(val);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Syndicate Investment Thesis &nbsp; </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="investmentThesis"
                value={investmentThesis}
                onChange={(val: string) => {
                  setInvestmentThesis(val);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>
                {" "}
                Syndicate Minimum Typical Investment (in Lakhs) &nbsp;{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="mininvestment"
                value={mininvestment}
                type="number"
                onChange={(val: number) => {
                  setMinInvestment(val);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>
                Syndicate Maximum Typical Investment (in Lakhs)&nbsp;{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="maxInvestment"
                value={maxInvestment}
                type="number"
                onChange={(val: number) => {
                  setMaxInvestment(val);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Syndicate Deal Per Year &nbsp; </Typography>
              <Tooltip title="How many deals the syndicate is planning to bring in an year?">
                <Help fontSize="small" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="dealPerYear"
                type="number"
                value={dealPerYear}
                onChange={(val: number) => {
                  setDealPerYear(val);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Syndicate Profile Image &nbsp; </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <FileUpload
              customStyles={{ maxWidth: 360 }}
              placeholder="select a file"
              helperText="upload your file here"
              selectedFile={(file: any) => setProfileImage(file)}
              multiple
              value={profileImage}
              accept={{
                "image/svg": [".png", ".svg"],
                "text/pdf": [".pdf"],
              }}
              title="File Upload Dialog Box"
              hideDocumentVault
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Syndicate Cover Image &nbsp; </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <FileUpload
              customStyles={{ maxWidth: 360 }}
              placeholder="select a file"
              helperText="upload your file here"
              selectedFile={(file: any) => setCoverImage(file)}
              multiple
              value={coverImage}
              accept={{
                "image/svg": [".png", ".svg"],
                "text/pdf": [".pdf"],
              }}
              title="File Upload Dialog Box"
              hideDocumentVault
            />
          </Grid>
          <Grid item xs={3} />
        </>
      ) : (
        <>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography> Max. Corpus (Lakhs) </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="maxCorpus"
                value={maxCorpus}
                type="number"
                onChange={(val: number) => {
                  setMaxCorpus(val);
                }}
              />{" "}
            </Box>
            <Box ml={2}> INR</Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>PreCommit Syndicate Tenure</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="PreCommit Syndicate Tenure"
                value={preCommitTenure}
                type="number"
                onChange={(val: number) => {
                  setPreCommitTenure(val);
                }}
              />{" "}
            </Box>
            <Box ml={2}>Years</Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Management Fee (per year)</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="Management Fee (per year)"
                value={managementFee}
                type="number"
                onChange={(val: number) => {
                  setManagementFee(val);
                }}
              />{" "}
            </Box>
            <Box ml={2}>%</Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Number of Drawdowns</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="Number of Drawdowns"
                value={drawdownNo}
                type="number"
                onChange={(val: number) => {
                  setDrawdownNo(val);
                }}
              />{" "}
            </Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Expected Drawdown Interval</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="Expected Drawdown Interval"
                value={drawdownInterval}
                type="number"
                onChange={(val: number) => {
                  setDrawdownInterval(val);
                }}
              />{" "}
            </Box>
            <Box ml={2}>Months</Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>LV Carry</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="LV Carry"
                value={lvCarry}
                type="number"
                onChange={(val: number) => {
                  setLvCarry(val);
                }}
              />{" "}
            </Box>
            <Box ml={2}>%</Box>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <Typography>Hurdle Rate</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Box width="100%">
              <CustomTextField
                name="Hurdle Rate"
                value={hurdleRate}
                type="number"
                onChange={(val: number) => {
                  setHurdleRate(val);
                }}
              />{" "}
            </Box>
            <Box ml={2}>%</Box>
          </Grid>
          <Grid item xs={3} />
        </>
      )}
      <Grid item xs={9} display="flex">
        <Box ml="auto">
          <ActionButton
            onClick={createSyndicate}
            isLoading={creating === API_STATUS.LOADING}
            disabled={
              creating === API_STATUS.LOADING ||
              !selectedInvestor?.value ||
              !name.trim() ||
              isDisable()
            }
          >
            Create Syndicate
          </ActionButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateSyndicate;
