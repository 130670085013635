import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import Loader from "../../../loader/loading";
import { StartupAdminActionType } from "../../constants/StartupAdminActions";
import { toast } from "react-toastify";
import { useContext } from "react";
import { useStyles } from "../startupPanel/styles";
import NoteModal from "../../../userManagement/components/modals/NoteModal";
import StartupManagement from "../../managers/startupManager";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { FileUpload } from "@trica-equity/ui-library.form-elements.file-upload";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../contexts/authContext";

const filter = createFilterOptions<any>();

type ParamsType = {
  startup_id: string;
};

export default function ShowStartup() {
  const { startup_id = "" } = useParams<ParamsType>();
  const authContext = useContext(AuthContext);
  const startupManagementManager = new StartupManagement(authContext);
  const [detail, setDetail] = useState<any>({});
  const [isNoteMoalOpen, setIsNoteModalOpen] = useState(false);

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [selectedPitchingFounder, setSelectedPitchingFounder] = useState<any>(
    {}
  );
  const [optionPitchingFounder, setOptionPitchingFounder] = useState<any>([]);
  const [loadingPitchingFounder, setLoadingPitchingFounder] = useState(false);

  const [selectedMailer, setSelectedMailer] = useState<any>({});
  const [optionMailer, setOptionMailer] = useState<any>([]);
  const [loadingMailer, setLoadingMailer] = useState(false);

  const [selectedPrimaryStartup, setSelectedPrimaryStartup] = useState<any>({});
  const [primaryStartupOptions, setPrimaryStartupOptions] = useState<any>([]);
  const [primaryStartupText, setPrimaryStartupText] = useState<any>("");
  const [loadingPrimaryStartup, setLoadingPrimaryStartup] = useState(false);

  const [mouFile, setMouFile] = useState([]);
  const [syndicateClosedThresold, setSyndicateClosedThresold] = useState<any>();
  const [fundCarry, setFundCarry] = useState<any>();
  const [leadCarry, setLeadCarry] = useState<any>();
  const [hurdleRate, setHurdleRate] = useState<any>();
  const [lvAllocation, setLvAllocation] = useState<any>();
  const [whatsappThresold, setWhatsappThresold] = useState<any>();
  const [whatsappSwitch, setWhatsappSwitch] = useState<boolean>(false);
  const [transactionFeePercent, setTransactionFeePercent] = useState<any>();
  const [transactionFee, setTransactionFee] = useState<any>();
  const [minCommitment, setMinCommitment] = useState<any>();
  const [panNumber, setPanNumber] = useState<any>();
  const [valuationDate, setValuationDate] = useState();

  const setData = (data: any) => {
    setSyndicateClosedThresold(data?.syndicate_closed);
    setFundCarry(data?.fund_carry);
    setLeadCarry(data?.lead_carry);
    setHurdleRate(data?.hurdle_rate);
    // setLvAllocation(0)
    setWhatsappThresold(data?.whatsapp_threshold);
    setWhatsappSwitch(data?.whastapp_notification_switch);
    setTransactionFeePercent(data?.transaction_fees_percentage);
    setTransactionFee(data?.transaction_fees_amount);
    setMinCommitment(data?.min_commitment);
    setPanNumber(data?.startup_pan_number);
  };

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const result = await startupManagementManager.getStartupDetail(id);
      if (result) {
        setDetail(result);
        setData(result);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Something Went Wrong");
    }
  };

  const handleActivate = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.ENABLE_DEAL_DASHBOARD_ACCESS
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Activated successfully");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeActivate = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.DISABLE_DEAL_DASHBOARD_ACCESS
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "De-Activated successfully");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const SetToAIF = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.SET_INVESTMENT_TYPE_AIF
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Set to AIF");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const SetToDirect = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.SET_INVESTMENT_TYPE_DIRECT
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Set to Direct");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePublish = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.PUBLISH
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Published successfully");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnPublish = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.UNPUBLISH
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "UnPublished successfully");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const disableSuperStealth = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.DISABLE_SUPER_STEALTH
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Super Stealth Mode Off");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const enableSuperStealth = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.ENABLE_SUPER_STEALTH
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Super Stealth Mode On");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const addMou = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.ADD_MOU,
        {
          document_url: mouFile[0],
        }
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "MoU Added");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  const removeMou = async () => {
    try {
      const result: any = await startupManagementManager.startupActions(
        startup_id,
        StartupAdminActionType.REMOVE_MOU,
        {
          document_url: mouFile[0],
        }
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "MoU Removed");
      await getData(startup_id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData(startup_id);
  }, [startup_id]);

  const handleCloseNoteModal = () => {
    setIsNoteModalOpen(false);
  };
  const handleOpenNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <Box p={2}>
      <NoteModal
        isOpen={isNoteMoalOpen}
        handleClose={handleCloseNoteModal}
        selected={{ id: detail?.id, name: detail?.name }}
        type="Startup"
      />
      <Grid container rowSpacing={2}>
        <Grid item xs={3}>
          <Box display="flex" alignItems="center" gap={2}>
            <ArticleIcon />
            <Typography variant="h5">{detail?.name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={9} textAlign="end">
          <Box display="inline-flex">
            <Box alignItems="center" gap={2} display="flex">
              <ActionButton onClick={handleOpenNoteModal}>
                View Notes
              </ActionButton>
            </Box>
          </Box>
        </Grid>

        {/* headers */}
        <Grid item xs={4}>
          <Typography variant="h6">Field</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Actions</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderWidth: 1 }} />
        </Grid>

        {/* user details */}

        <Grid item xs={4}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{detail?.name}</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Joined on</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{detail?.joined_on}</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Founders</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {detail?.founders?.length ? detail?.founders?.join(",") : "nil"}
          </Typography>
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Publish Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.published ? "Published" : "UnPublished"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="text"
            onClick={!!detail?.published ? handleUnPublish : handlePublish}
          >
            {!!detail?.published ? "UnPublished" : "Publish"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Deal Dashboard Accessibility</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.deal_dashboard_access ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {!!detail?.deal_dashboard_access ? (
            <Button variant="text" onClick={handleDeActivate}>
              Disable
            </Button>
          ) : (
            <Button variant="text" onClick={handleActivate}>
              Enable
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Community Startup</Typography>
        </Grid>
        <Grid item xs={4}>
          {/* <Typography>{!!detail?.user?.confirmed_email ? "Yes" : "No"}</Typography> */}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Investment Type</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.investment_type ? detail?.investment_type : "Not Set"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={3}>
            {detail?.investment_type !== "aif" && (
              <Button variant="text" onClick={SetToAIF}>
                Set to AIF
              </Button>
            )}
            {detail?.investment_type !== "direct" && (
              <Button variant="text" onClick={SetToDirect}>
                Set to Direct
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>MoU</Typography>
        </Grid>
        {detail?.mou_download_url ? (
          <>
            <Grid item xs={4}>
              <Button onClick={removeMou}>Remove MoU</Button>
            </Grid>
            <Grid item xs={4} />
          </>
        ) : (
          <>
            <Grid item xs={4}>
              <FileUpload
                placeholder="Choose File *"
                value={mouFile}
                selectedFile={(file: any) => setMouFile(file)}
                required
                width="98%"
                // accept={{
                //   "image/svg": [".png", ".svg", ".jpg", ".jpeg", ".doc", ".docx"],
                //   "text/pdf": [".pdf"]
                // }}
                title="Choose File"
                hideDocumentVault
              />
            </Grid>
            <Grid item xs={4}>
              <Button onClick={addMou}>Add MoU</Button>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Write Up By Startup</Typography>
        </Grid>
        <Grid item xs={4}>
          {/* <Typography>{!!detail?.user?.phone_no ? detail?.user?.phone_no : "-"}</Typography> */}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Pitching Founder</Typography>
        </Grid>
        <Grid item xs={4}>
          <Formik initialValues={{ hashtag: "" }} onSubmit={() => {}}>
            <>
              <Autocomplete
                loading={loadingPitchingFounder}
                fullWidth
                multiple
                className={classes.root}
                options={optionPitchingFounder}
                value={optionPitchingFounder.find(
                  (option: any) =>
                    option["value"] === selectedPitchingFounder?.value
                )}
                popupIcon={
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down"
                  />
                }
                onChange={(event: any, value: any) => {
                  if (typeof value === "string") {
                    setSelectedPitchingFounder({
                      label: value,
                      value,
                    });
                  } else if (value && value.inputValue) {
                    // Create a new value from the user input

                    setSelectedPitchingFounder({
                      label: value.inputValue,
                      value: value.inputValue,
                    });
                  } else {
                    setSelectedPitchingFounder(value);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      label: `#${inputValue}`,
                      value: inputValue,
                      inputValue,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return `Add new tag : #${option.inputValue}`;
                  }
                  // Regular option
                  return option.label;
                }}
                renderTags={(
                  value: readonly {
                    label: string;
                    value: string;
                    inputValue: string;
                  }[],
                  getTagProps
                ) =>
                  value.map(
                    (
                      option: {
                        label: string;
                        value: string;
                        inputValue: string;
                      },
                      index: number
                    ) => (
                      <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    )
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Choose Pitching Founder"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    helperText={"Please enter 1 or more character"}
                  />
                )}
                PopperComponent={(props) => {
                  return props?.open ? (
                    <Box className={`${classes.optionsBox}`}>
                      <div
                        className={classes.menuBox}
                        style={{
                          maxHeight: "200px",
                        }}
                      >
                        <>{props.children}</>
                      </div>
                      <div
                        onMouseDown={(event) => event.preventDefault()}
                      ></div>
                    </Box>
                  ) : (
                    <></>
                  );
                }}
                PaperComponent={(props) => {
                  return (
                    <Box
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "none !important",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
              />
            </>
          </Formik>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Save</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Visibility</Typography>
        </Grid>
        <Grid item xs={4}>
          <CoreDropdown
            options={[
              { label: "Limited Visibility to Investors", value: "limited" },
              {
                label: "Visible to All Investors",
                value: "visible",
              },
              {
                label: "Stealth/Private",
                value: "stealth",
              },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Save</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Super Visibility Option</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Super Stealth Mode {!!detail?.super_visibility ? "On" : "Off"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {!!detail?.super_visibility ? (
            <Button variant="text" onClick={disableSuperStealth}>
              Switch Off
            </Button>
          ) : (
            <Button variant="text" onClick={enableSuperStealth}>
              Switch On
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Startup As Private Normal Syndicate</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography></Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Startup As Private Zeno Syndicate</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{detail?.zeno ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Add mailer list to allowed list</Typography>
        </Grid>
        <Grid item xs={4}>
          <Formik initialValues={{ hashtag: "" }} onSubmit={() => {}}>
            <>
              <Autocomplete
                loading={loadingMailer}
                fullWidth
                multiple
                className={classes.root}
                options={optionMailer}
                value={optionMailer.find(
                  (option: any) => option["value"] === selectedMailer?.value
                )}
                popupIcon={
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down"
                  />
                }
                onChange={(event: any, value: any) => {
                  if (typeof value === "string") {
                    setSelectedMailer({
                      label: value,
                      value,
                    });
                  } else if (value && value.inputValue) {
                    // Create a new value from the user input

                    setSelectedMailer({
                      label: value.inputValue,
                      value: value.inputValue,
                    });
                  } else {
                    setSelectedMailer(value);
                  }
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }

                  return option.label;
                }}
                renderTags={(
                  value: readonly {
                    label: string;
                    value: string;
                    inputValue: string;
                  }[],
                  getTagProps
                ) =>
                  value.map(
                    (
                      option: {
                        label: string;
                        value: string;
                        inputValue: string;
                      },
                      index: number
                    ) => (
                      <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    )
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Choose Mailer lists"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    helperText={"Please enter 1 or more character"}
                  />
                )}
                PopperComponent={(props) => {
                  return props?.open ? (
                    <Box className={`${classes.optionsBox}`}>
                      <div
                        className={classes.menuBox}
                        style={{
                          maxHeight: "200px",
                        }}
                      >
                        <>{props.children}</>
                      </div>
                      <div
                        onMouseDown={(event) => event.preventDefault()}
                      ></div>
                    </Box>
                  ) : (
                    <></>
                  );
                }}
                PaperComponent={(props) => {
                  return (
                    <Box
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "none !important",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
              />
            </>
          </Formik>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update Allowed Mailer List</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Syndicate Close Threshold %</Typography>
        </Grid>
        <Grid item xs={4}>
          {detail?.syndicate_closed
            ? `Current Threshold:${syndicateClosedThresold}%`
            : "-"}
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={syndicateClosedThresold}
            onChange={(val: any) => setSyndicateClosedThresold(val)}
          />
          <Button variant="text">Update Threshold</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Fund Carry %</Typography>
        </Grid>
        <Grid item xs={4}>
          {fundCarry ? `Current Fund Carry:${fundCarry}%` : "-"}
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={fundCarry}
            onChange={(val: any) => setFundCarry(val)}
          />
          <Button variant="text">Update Fund Carry</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Lead Carry %</Typography>
        </Grid>
        <Grid item xs={4}>
          {leadCarry ? `Current Lead Carry:${leadCarry}%` : "-"}
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={leadCarry}
            onChange={(val: any) => setLeadCarry(val)}
          />
          <Button variant="text">Update Lead Carry</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Show Lead Investor Commitment Switch</Typography>
        </Grid>
        <Grid item xs={4}>
          {detail?.lead_show_commitment ? "Yes" : "No"}
        </Grid>
        <Grid item xs={4}>
          {detail?.lead_show_commitment ? (
            <Button variant="text">Hide</Button>
          ) : (
            <Button variant="text">Show</Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Lead Carry Terms & Conditions</Typography>
        </Grid>
        <Grid item xs={4}>
          {detail?.lead_investor_tacs ? "Yes" : "No"}
        </Grid>
        <Grid item xs={4}>
          {detail?.lead_investor_tacs ? (
            <Button variant="text">Disable</Button>
          ) : (
            <Button variant="text">Enable</Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Hurdle Rate (Enter ONLY Digit)</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={hurdleRate}
            onChange={(val: any) => setHurdleRate(val)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>LV Allocation (Enter ONLY Digit)</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={lvAllocation}
            onChange={(val: any) => setLvAllocation(val)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Startup DD, Pro Rata Rights</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={3}>
            <FormControlLabel control={<Checkbox />} label="DD" />
            <FormControlLabel control={<Checkbox />} label="Pro Rata Rights" />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Submit</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Switch to toggle Off Scheme Consent</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={3}>
            <FormControlLabel control={<Checkbox />} label="Don't Show" />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Submit</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Whatsapp Notification Threshold %</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={whatsappThresold}
            onChange={(val: any) => setWhatsappThresold(val)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update Threoshold</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Whatsapp Notification Switch</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={3}>
            <FormControlLabel
              control={
                <Checkbox
                  value={whatsappSwitch}
                  onChange={(e: any) => setWhatsappSwitch(e.target.checked)}
                />
              }
              label="Switch Off Whatsapp Notification For This Deal"
            />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Submit</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>
            Show all Commitments & investor profile details
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Show All</Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Show All Commitments For Exit Data Updation</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Show All</Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Investor Connect Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography></Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Pitching Session</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Button variant="text">Add</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Lightning Deal</Typography>
        </Grid>
        <Grid item xs={4}>
          No
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Enable</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Transaction Fees Percentage</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={transactionFeePercent}
            onChange={(val: any) => setTransactionFeePercent(val)}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="Override Transaction Fees for all (Mandatory to be checked for changes)"
            />
            <Button variant="text">Save</Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Transaction Fees Amount</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={transactionFee}
            onChange={(val: any) => setTransactionFee(val)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Save</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Minimum Commitment</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={minCommitment}
            onChange={(val: any) => setMinCommitment(val)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Startups PAN Number</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={panNumber}
            onChange={(val: any) => setPanNumber(val)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Share Price At First</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            label="Share Price"
            placeholder="INR"
            customStyles={{ marginBottom: 1 }}
          />

          <CustomTextField label="Share Price Comment" />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Share Price Valuation </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={2} marginBottom={1}>
            <CustomTextField label="Valuation" />
            <CoreDropdown
              options={[
                { label: "INR", value: "inr" },
                { label: "USD", value: "usd" },
              ]}
            />
            <Box className="customDatePicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="cd"
                    value={dayjs(valuationDate)}
                    onChange={(newValue: any) => setValuationDate(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Stack>

          <CustomTextField label="Share Price Comment" />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Investment Amount</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={2} marginBottom={1} alignItems="center">
            <CustomTextField label="Investment Amount" />
            <Typography>INR</Typography>
            <CustomTextField label="GP Contribution" />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography>Date Of Transfer</Typography> &nbsp; &nbsp;
            <Box className="customDatePicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DemoContainer components={["DatePicker"]}> */}
                <DatePicker />
                {/* </DemoContainer> */}
              </LocalizationProvider>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Save</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Startup Screening</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Move To Initial Screening</Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Primary Startup</Typography>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            loading={loadingPrimaryStartup}
            fullWidth
            className={classes.root}
            options={primaryStartupOptions}
            value={primaryStartupOptions.find(
              (option: any) => option["value"] === selectedPrimaryStartup?.value
            )}
            popupIcon={
              <img
                src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                alt="arrow-down"
              />
            }
            onChange={(event: any, value: any) => {
              setSelectedPrimaryStartup(value);
              setPrimaryStartupOptions([]);
            }}
            getOptionLabel={(option: any) => `${option.label}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Startup"
                variant="outlined"
                size="small"
                className={classes.textField}
                onChange={(e) => {
                  setLoadingPrimaryStartup(!!e.target.value);
                  setPrimaryStartupText(e.target.value);
                }}
                helperText={"Please enter 1 or more character"}
              />
            )}
            PopperComponent={(props) => {
              return props?.open ? (
                <Box className={`${classes.optionsBox}`}>
                  <div
                    className={classes.menuBox}
                    style={{
                      maxHeight: "200px",
                    }}
                  >
                    <>{props.children}</>
                  </div>
                  <div onMouseDown={(event) => event.preventDefault()}></div>
                </Box>
              ) : (
                <></>
              );
            }}
            PaperComponent={(props) => {
              return (
                <Box
                  sx={{
                    "& .MuiAutocomplete-listbox": {
                      maxHeight: "none !important",
                    },
                  }}
                  {...props}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Startup Follow Up</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" gap={3}>
            <FormControlLabel control={<Checkbox />} label="ShutDown" />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Submit</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography>Fundraising Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight="bold">Listed in Tab</Typography>
          <Typography>Syndicate Funded Outside LV Platform</Typography>
          <Typography>20-10-2016</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>**Note</Typography>
          <Typography>
            1. Kindly approve this startup from Final Screening{" "}
            <a href="#">Go Here</a>{" "}
          </Typography>
          <Typography>
            2. Make sure the Scheme Document is Generated before making it LIVE{" "}
            <a href="#">Go Here</a>{" "}
          </Typography>
          <Typography>
            3. Make sure startup has a Pitching Founder<a href="#">Go Here</a>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
}
