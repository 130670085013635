import React, { useState } from "react";
import { CustomTextField } from "@trica-equity/ui-library.form-elements.input-field";
import { CoreDropdown } from "@trica-equity/ui-library.dropdowns.core-dropdown";
import { CommonModal } from "@trica-equity/ui-library.modals.common-modal";
import makeStyles from "../../../styles";
import { FileUpload } from "@trica-equity/ui-library.form-elements.file-upload";
import { toast } from "react-toastify";
import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
import { OutlinedButton } from "@trica-equity/ui-library.buttons.outlined-button";
import DocumentManagement from "../../managers/documentManager";
import { AuthContext } from "../../../../contexts/authContext";
import { useContext } from "react";
import {
  DocumentCategoryOptions,
  DocumentNameOptions,
} from "../../constants/documentConstants";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

export default function UploadDocumentModal(props: any) {
  const classes = makeStyles();
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);

  const { openModal, handleModal } = props;

  const [selectedDeal, setSelectedDeal] = useState<any>({});
  const [dealOptions, setDealOptions] = useState<any>([]);
  const handleSearchDealName = async (value: string) => {
    try {
      let result = await documentManagementManager.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setDealOptions(result);
    } catch (error) {
      console.log(error);
    }
  };

  const [documentCategory, setDocumentCategory] = useState<any>({});
  const [documentName, setDocumentName] = useState<any>({});
  const [file, setFile] = useState([]);
  const [notes, setNotes] = useState("");

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitButtonLoading(true);
      const data = {
        startup_id: selectedDeal?.value,
        document_category: documentCategory?.value,
        document_name: documentName?.value,
        document_url: file[0],
        document_notes: notes,
      };

      const result = await documentManagementManager.uploadDealDocument(data);
      toast.success("Document Uploaded Successfully");
      setIsSubmitButtonLoading(false);
      handleModal();
    } catch (error: any) {
      toast.error(error.message ?? "Something Went Wrong");
      setIsSubmitButtonLoading(false);
    }
  };

  const cancel = () => {
    handleModal();
  };

  return (
    <>
      <div>
        <CommonModal
          submitText="continue"
          isOpen={openModal}
          handleSubmit={handleSubmit}
          handleClose={handleModal}
          title="Upload Deal Docuement"
          hideButtons
        >
          <div style={{ width: "500px", overflow: "auto" }}>
            <div className={classes.fieldItem} style={{ marginTop: "5px" }}>
              <LvAutoComplete
                label="Enter Deal Name"
                options={dealOptions}
                setOptions={setDealOptions}
                selectedObject={selectedDeal}
                setSelectedObject={setSelectedDeal}
                onSelect={() => setDealOptions([])}
                onChange={handleSearchDealName}
              />
            </div>

            <div className={classes.fieldItem}>
              <CoreDropdown
                label="Select Document Category"
                options={DocumentCategoryOptions}
                value={documentCategory}
                onClickMenuItem={(value: any) => setDocumentCategory(value)}
              />
            </div>
            <div className={classes.fieldItem}>
              <CoreDropdown
                label="Select Document Name"
                options={DocumentNameOptions(documentCategory?.value)}
                value={documentName}
                onClickMenuItem={(value: any) => setDocumentName(value)}
              />
            </div>

            <div className={classes.fieldItem}>
              <FileUpload
                placeholder="Choose File *"
                value={file}
                selectedFile={(file: any) => setFile(file)}
                required
                width="100%"
                // accept={{
                //   "image/svg": [".png", ".svg", ".jpg", ".jpeg", ".doc", ".docx"],
                //   "text/pdf": [".pdf"]
                // }}
                title="Choose File"
                hideDocumentVault
              />
            </div>
            <div className={classes.fieldItem}>
              <CustomTextField
                label="Notes"
                width="100%"
                placeholder="Notes"
                name="notes"
                id="notes"
                multiline
                minRows={4}
                value={notes}
                onChange={(val: any) => setNotes(val)}
              />
            </div>
            <div className={classes.buttonContainer}>
              <div>
                <OutlinedButton
                  customStyles={{ width: "238px" }}
                  onClick={cancel}
                >
                  Cancel
                </OutlinedButton>
              </div>
              <div>
                <ActionButton
                  customStyles={{ width: "238px" }}
                  isLoading={isSubmitButtonLoading}
                  disabled={isSubmitButtonLoading}
                  onClick={handleSubmit}
                >
                  Submit
                </ActionButton>
              </div>
            </div>
          </div>
        </CommonModal>
      </div>
    </>
  );
}
